.monaco-placeholder {
  position: absolute;
  display: block;
  white-space: pre-wrap;
  left: 11px;
  font-size: 14px;
  color: #B1B1BA;
  pointer-events: none;
  user-select: none;
}

.monaco-editor .scroll-decoration {
  box-shadow: none !important
}

.monaco-editor.focused {
  --vscode-editor-background: #FFFFFF;
}

.margin {
  display: none !important;
}

@for $i from 0 through 30 {
  .bracket-highlighting-#{$i} {
    color: black !important
  }
}
.codicon-symbol-keyword:before {
  content: " " !important;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
  background-image: url('../../../static/suggestionIcon.png')
}