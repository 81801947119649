[contenteditable="true"].single-line {
    white-space: nowrap;
    overflow: hidden;
}

// [contenteditable="true"].single-line br {
//    display: none;
// }

[contenteditable="true"].single-line * {
    // display: inline;
    white-space: nowrap;
}