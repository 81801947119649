@use "sass:map";
@import '../../styles/variables.scss';

.text-input {
	padding: 4px 6px;
	border: solid 2px gray;
	border-radius: 6px;
	font-family: Helvetica,Arial,sans-serif;	
}

.text-input:focus {
	border-color: cornflowerblue;
	outline: none;
}

.text-input p {
	padding: 0;
	margin: 0;
}