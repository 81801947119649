@use "sass:map";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');

@font-face {
    font-family: 'Menlo';
    src: url(../fonts/Menlo-Regular.ttf);
}

@import './variables.scss';

* {
    margin: 0;
    padding: 0;
}

body {
    height: 100%;
    font-size: map.get($font-sizes, "body");
    font-weight: map.get($font-weights, "normal");
    font-family: map.get($fonts, "main");
    min-width: 1024px;
    color: map.get($colors-basic, "textBlack");
    line-height: normal;
}

* {
   font-family: map.get($fonts, "main");
};

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ͼ1 .cm-line {
  padding: 0 12px !important;
}

.ͼ1 .cm-content {
  padding: 8px 0;
}

.inline .ͼ1 .cm-content {
  padding: 4px 0 0;
}

.ace_editor, .ace_editor *{
  font-family: "Monaco", "Menlo", "Ubuntu Mono", "Droid Sans Mono", "Consolas", monospace !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
}

.ace_editor {
  border-radius: 4px;
  padding: 0 !important;
}

.ace_content {
  background-color: #F8F8F9 !important;
}

.error_content .ace_content {
  background-color: #fef4f4 !important;
}

.ace_hidden-cursors .ace_cursor {
  opacity: 0 !important;
  pointer-events: none !important;
}

.ace_marker-layer .ace_bracket {
  opacity: 0 !important;
}

.ace_scroller.ace_scroll-left:after {
  box-shadow: none !important;
}

.ace_layer.ace_marker-layer {
  overflow-y: visible !important;
}

.ace_placeholder {
  color: map.get($colors-basic, "textGray") !important;
  padding: 0 !important;
  transform: scale(1) !important;
  margin: 0 0 0 4px !important;
}
  

.inline .ͼ1 .cm-line {
  padding: 0 2px !important;
}
