/* Define zoom-in animation with bounce */
@keyframes zoomIn {
    0% {
      opacity: 0;
      transform: scale3d(0.5, 0.5, 0.5);
    }
    50% {
      opacity: 1;
    }
    70% {
      transform: scale3d(1.01, 1.01, 1.01);
    }
    100% {
      transform: scale3d(1, 1, 1);
    }
  }

@mixin zoomInAnimation {
    animation: zoomIn 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    animation-fill-mode: both;
}