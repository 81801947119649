@import "https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap";
@font-face {
  font-family: Menlo;
  src: url("Menlo-Regular.26a504ce.ttf");
}

* {
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
  min-width: 1024px;
  color: #080828;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

* {
  font-family: Open Sans, sans-serif;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.ͼ1 .cm-line {
  padding: 0 12px !important;
}

.ͼ1 .cm-content {
  padding: 8px 0;
}

.inline .ͼ1 .cm-content {
  padding: 4px 0 0;
}

.ace_editor, .ace_editor * {
  letter-spacing: 0 !important;
  font-family: Monaco, Menlo, Ubuntu Mono, Droid Sans Mono, Consolas, monospace !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.ace_editor {
  border-radius: 4px;
  padding: 0 !important;
}

.ace_content {
  background-color: #f8f8f9 !important;
}

.error_content .ace_content {
  background-color: #fef4f4 !important;
}

.ace_hidden-cursors .ace_cursor {
  opacity: 0 !important;
  pointer-events: none !important;
}

.ace_marker-layer .ace_bracket {
  opacity: 0 !important;
}

.ace_scroller.ace_scroll-left:after {
  box-shadow: none !important;
}

.ace_layer.ace_marker-layer {
  overflow-y: visible !important;
}

.ace_placeholder {
  color: #93939f !important;
  margin: 0 0 0 4px !important;
  padding: 0 !important;
  transform: scale(1) !important;
}

.inline .ͼ1 .cm-line {
  padding: 0 2px !important;
}

.a4Nfeq_loader {
  height: 16px;
  height: 16px;
  -ms-animation-name: spin;
  -ms-animation-duration: 2000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  justify-content: center;
  align-items: center;
  animation-name: a4Nfeq_spin;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  display: flex;
  position: relative;
}

.a4Nfeq_loader.a4Nfeq_black svg * {
  fill: #080828;
}

.a4Nfeq_loader svg * {
  fill: #4149f7;
}

.a4Nfeq_loader.a4Nfeq_disabled svg * {
  fill: #bfc1ca;
}

.a4Nfeq_loader.a4Nfeq_large svg {
  transform: scale(2);
}

.a4Nfeq_loader.a4Nfeq_small svg {
  transform: scale(.5);
}



@-moz-keyframes a4Nfeq_spin {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes a4Nfeq_spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes a4Nfeq_spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.CM0Nmq_button {
  box-sizing: border-box;
  color: #fff;
  height: 36px;
  cursor: pointer;
  background-color: #4149f7;
  border: 1px solid #4149f7;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding-left: 19px;
  padding-right: 19px;
  font-size: 14px;
  font-weight: 600;
  transition: all .2s;
  display: flex;
}

.CM0Nmq_button[disabled] {
  cursor: default;
  color: #bfc1ca;
  background-color: #f3f4f6;
  border: 1px solid #f3f4f6;
}

.CM0Nmq_button[disabled]:hover, .CM0Nmq_button[disabled]:active {
  background-color: #f3f4f6;
  border: 1px solid #f3f4f6;
}

.CM0Nmq_button.CM0Nmq_M {
  height: 32px;
}

.CM0Nmq_button.CM0Nmq_gap {
  gap: 12px;
}

.CM0Nmq_button:hover {
  background-color: #1e249d;
  border: 1px solid #1e249d;
}

.CM0Nmq_button:active {
  background-color: #666ce4;
  border: 1px solid #666ce4;
}

.CM0Nmq_button svg * {
  fill: #fff;
}

.CM0Nmq_button.CM0Nmq_secondary {
  color: #080828;
  background-color: #f3f4f6;
  border: 1px solid #f3f4f6;
}

.CM0Nmq_button.CM0Nmq_secondary svg * {
  fill: #080828;
}

.CM0Nmq_button.CM0Nmq_secondary[disabled]:hover, .CM0Nmq_button.CM0Nmq_secondary[disabled]:active {
  background-color: #f3f4f6;
  border: 1px solid #f3f4f6;
}

.CM0Nmq_button.CM0Nmq_secondary:hover {
  background-color: #f3f4f6;
  border: 1px solid #93939f;
}

.CM0Nmq_button.CM0Nmq_secondary:active {
  background-color: #f3f4f6;
  border: 1px solid #e8e9ed;
}

.CM0Nmq_button.CM0Nmq_colorRed {
  color: #e4315f;
}

.CM0Nmq_button.CM0Nmq_colorRed svg * {
  fill: #e4315f;
}

.CM0Nmq_button.CM0Nmq_colorGreen {
  color: #3fb245;
}

.CM0Nmq_button.CM0Nmq_colorGreen svg * {
  fill: #3fb245;
}

.CM0Nmq_button.CM0Nmq_hiddenFill svg {
  width: 24px;
  height: 24px;
}

.CM0Nmq_button.CM0Nmq_hiddenFill svg * {
  fill: none;
}

.CM0Nmq_button.CM0Nmq_red {
  background-color: #e4315f;
  border: 1px solid #e4315f;
}

.CM0Nmq_button.CM0Nmq_red:hover {
  background-color: #b50e3a;
  border: 1px solid #b50e3a;
}

.CM0Nmq_button.CM0Nmq_red:active {
  background-color: #fb7094;
  border: 1px solid #fb7094;
}

.CM0Nmq_button.CM0Nmq_red[disabled] {
  opacity: .2;
  cursor: default;
  color: #fff;
}

.CM0Nmq_button.CM0Nmq_red[disabled]:hover {
  background-color: #e4315f;
  border: 1px solid #e4315f;
}

.CM0Nmq_button.CM0Nmq_short {
  padding-left: 9px;
  padding-right: 9px;
}

.ULccdG_actionText {
  background-color: inherit;
  cursor: pointer;
  color: #4149f7;
  border: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  display: inline-block;
}

.ULccdG_actionText[disabled] {
  cursor: initial;
  color: #bfc1ca;
}

.ULccdG_actionText.ULccdG_typeBlack {
  color: #080828;
  font-weight: 600;
}

.ULccdG_actionText.ULccdG_typeBlack:hover {
  color: #4149f7;
}

.ULccdG_actionText.ULccdG_typeBold {
  font-weight: 600;
}

.wZ9WEa_actionIcon {
  box-sizing: border-box;
  height: 16px;
  width: 17px;
  cursor: pointer;
  background-color: #0000;
  border: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.wZ9WEa_actionIcon:hover svg *, .wZ9WEa_actionIcon:active svg *, .wZ9WEa_actionIcon.wZ9WEa_active svg * {
  fill: #4149f7;
}

.wZ9WEa_actionIcon.wZ9WEa_small {
  width: 12px;
  height: 12px;
}

.wZ9WEa_actionIcon.wZ9WEa_large {
  width: 24px;
  height: 24px;
}

.wZ9WEa_actionIcon.wZ9WEa_hoverDisabled:hover svg *, .wZ9WEa_actionIcon svg * {
  fill: #080828;
}

.wZ9WEa_actionIcon.wZ9WEa_speedometer svg *, .wZ9WEa_actionIcon.wZ9WEa_speedometer:hover svg *, .wZ9WEa_actionIcon.wZ9WEa_speedometer:active svg *, .wZ9WEa_actionIcon.wZ9WEa_speedometer.wZ9WEa_active svg * {
  fill: #fff;
  stroke: #4149f7;
}

.wZ9WEa_actionIcon.wZ9WEa_speedometer.wZ9WEa_hoverDisabled:hover svg * {
  fill: #fff;
  stroke: #080828;
}

.wZ9WEa_actionIcon.wZ9WEa_hiddenFill svg * {
  fill: none;
}

.wZ9WEa_tooltipStyled :hover {
  cursor: default !important;
}

.wZ9WEa_tooltipStyled a, .wZ9WEa_tooltipStyled a:active, .wZ9WEa_tooltipStyled a:visited {
  color: #fff;
  text-decoration: underline;
}

.wZ9WEa_tooltipStyled a:hover {
  cursor: pointer !important;
}

.V2H3Ya_toastsContainer {
  max-height: 100vh;
  max-width: 480px;
  width: 100%;
  z-index: 99999;
  box-sizing: border-box;
  flex-direction: column;
  gap: 8px;
  display: flex;
  position: fixed;
  top: 18px;
  left: 50%;
  transform: translateX(-50%);
}

.V2H3Ya_toast {
  box-sizing: border-box;
  width: 100%;
  background-color: #f7fef7;
  border: 1px solid #3fb245;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px 9px;
  display: flex;
}

.V2H3Ya_toast .V2H3Ya_group {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;
}

.V2H3Ya_toast .V2H3Ya_group svg {
  min-width: 16px;
}

.V2H3Ya_toast .V2H3Ya_group .V2H3Ya_groupContent {
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-right: 12px;
}

.V2H3Ya_toast .V2H3Ya_iconWrapper {
  min-height: 24px;
  min-width: 24px;
  justify-content: center;
  align-self: flex-start;
  align-items: center;
  display: flex;
}

.V2H3Ya_toast.V2H3Ya_error {
  background-color: #fff5f5;
  border: 1px solid #e4315f;
}

.V2H3Ya_toast .V2H3Ya_title {
  font-weight: 600;
}

.szOD2q_projectSelection {
  width: 100%;
  background-color: #0000;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  display: flex;
  position: relative;
  overflow: visible;
}

.szOD2q_group {
  width: 100%;
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  display: flex;
  position: relative;
}

.szOD2q_group.szOD2q_openedList {
  background-color: #f2f7ff;
}

.szOD2q_group.szOD2q_openedList:before {
  content: "";
  width: 2px;
  height: 100%;
  background: #4149f7;
  border-radius: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.szOD2q_iconGroup {
  flex-direction: row;
  display: flex;
  overflow: hidden;
}

.szOD2q_icon {
  justify-content: center;
  align-items: center;
  margin: auto 12px;
  display: flex;
}

.szOD2q_titleGroup {
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.szOD2q_actionGroup {
  margin: auto 6px;
}

.szOD2q_heading {
  color: #93939f;
  font-size: 11px;
}

.szOD2q_title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.szOD2q_altTitle {
  color: #4149f7;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.szOD2q_centered {
  align-self: center;
}

.AJ9WsW_navigation {
  width: 100%;
  background-color: #0000;
}

.AJ9WsW_navigation a {
  color: #080828;
  text-decoration: none;
}

.AJ9WsW_navItem {
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  padding: 8px 0;
  display: flex;
}

.AJ9WsW_navItem .AJ9WsW_icon {
  justify-content: center;
  align-items: center;
  margin: auto 20px;
  display: flex;
}

.AJ9WsW_navItem .AJ9WsW_icon svg {
  width: 16px;
  height: 16px;
}

.AJ9WsW_navItem.AJ9WsW_active {
  color: #4149f7;
  background-color: #f6f6ff;
  position: relative;
}

.AJ9WsW_navItem.AJ9WsW_active:before {
  content: "";
  width: 2px;
  height: 100%;
  background: #4149f7;
  border-radius: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.AJ9WsW_navItem.AJ9WsW_active .AJ9WsW_icon svg * {
  fill: #4149f7;
}

.AJ9WsW_navItem.AJ9WsW_active .AJ9WsW_icon.AJ9WsW_typeStroke svg * {
  stroke: #4149f7;
  fill: unset;
}

.AJ9WsW_navItem.AJ9WsW_disabled {
  color: #93939f;
  cursor: not-allowed;
}

.AJ9WsW_navItem.AJ9WsW_disabled .AJ9WsW_icon.AJ9WsW_typeStroke svg * {
  stroke: #93939f;
  fill: unset;
}

.AJ9WsW_navItem.AJ9WsW_disabled svg * {
  fill: #93939f;
}

.AJ9WsW_roles.AJ9WsW_active svg * {
  fill: none;
  stroke: #4149f7;
}

.jRV3UG_projectSelection {
  width: 100%;
  background-color: #0000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: visible;
}

.jRV3UG_bottom {
  width: 100%;
  height: 19px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 6px 0 7px;
  display: flex;
}

.jRV3UG_version {
  font-size: 11px;
}

.jRV3UG_sendFeedback {
  display: none;
}

.jRV3UG_group {
  width: 100%;
  border-top: 1px solid #e8e9ed;
  border-bottom: 1px solid #e8e9ed;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  display: flex;
}

.jRV3UG_iconGroup {
  flex-direction: row;
  display: flex;
  overflow: hidden;
}

.jRV3UG_actionGroup {
  margin: auto 6px;
}

.jRV3UG_popupMenu {
  width: 200px;
  z-index: 9999;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  bottom: 12px;
  left: 12px;
  box-shadow: 0 2px 6px #0808281a;
}

.jRV3UG_popupMenu li {
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 12px;
  display: flex;
}

.jRV3UG_icon {
  justify-content: center;
  align-items: center;
  margin: auto 12px;
  display: flex;
}

.jRV3UG_icon img.jRV3UG_logo {
  width: 32px;
  height: 32px;
}

.jRV3UG_titleGroup {
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.jRV3UG_heading {
  color: #93939f;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 11px;
  overflow: hidden;
}

.jRV3UG_title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dIM72a_sidepanel {
  min-height: 100%;
  color: #080828;
  width: 200px;
  height: 100vh;
  background-color: #fbfcff;
  border-right: 1px solid #e8e9ed;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.DMcEJG_main {
  box-sizing: border-box;
  flex-direction: row;
  display: flex;
}

.EJenLG_loaderWrapper {
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.CWcyqG_checkboxWrap {
  cursor: pointer;
  align-items: center;
  margin-right: 40px;
  display: flex;
}

.CWcyqG_checkboxWrap input {
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  white-space: nowrap;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.CWcyqG_checkboxWrap b {
  font-weight: 700;
}

.CWcyqG_checkboxWrap .CWcyqG_small {
  width: 16px;
  height: 16px;
}

.CWcyqG_checkboxWrap .CWcyqG_middle {
  width: 24px;
  height: 24px;
}

.CWcyqG_checkboxWrap .CWcyqG_checkbox {
  box-sizing: border-box;
  border: 1px solid #080828;
  border-radius: 2px;
  margin-right: 12px;
  transition: all .15s;
  display: inline-block;
}

.CWcyqG_checkboxWrap .CWcyqG_checkbox.CWcyqG_checked svg {
  fill: none;
  stroke: #080828;
  stroke-width: 2px;
}

.CWcyqG_checkboxWrap .CWcyqG_checkbox.CWcyqG_unchecked svg {
  display: none;
}

.CWcyqG_checkboxWrap span {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.CWcyqG_checkboxWrap.CWcyqG_colored .CWcyqG_checkbox {
  border: 1px solid #93939f;
  border-radius: 4px;
}

.CWcyqG_checkboxWrap.CWcyqG_colored .CWcyqG_checkbox.CWcyqG_checked {
  background: #4149f7;
  border: 1px solid #4149f7;
}

.CWcyqG_checkboxWrap.CWcyqG_colored .CWcyqG_checkbox.CWcyqG_checked svg {
  stroke: #fff;
}

.CWcyqG_checkboxWrap.CWcyqG_colored .CWcyqG_checkbox.CWcyqG_unchecked {
  background: #f8f8f9;
  border: 1px solid #e8e9ed;
}

.CWcyqG_checkboxWrap.CWcyqG_disabled {
  cursor: not-allowed;
}

.CWcyqG_checkboxWrap.CWcyqG_disabled .CWcyqG_checkbox {
  border: 1px solid #93939f;
  border-radius: 4px;
}

.CWcyqG_checkboxWrap.CWcyqG_disabled .CWcyqG_checkbox.CWcyqG_checked {
  background: #bfc1ca;
  border: 1px solid #bfc1ca;
}

.CWcyqG_checkboxWrap.CWcyqG_disabled .CWcyqG_checkbox.CWcyqG_checked svg {
  stroke: #fff;
}

.CWcyqG_checkboxWrap.CWcyqG_disabled .CWcyqG_checkbox.CWcyqG_unchecked {
  background: #f8f8f9;
  border: 1px solid #e8e9ed;
}

.CWcyqG_checkboxWrap.CWcyqG_disabled span {
  color: #bfc1ca;
}

.WryqfG_dbIcon {
  width: 32px;
  height: 32px;
  background-color: #f3f4f6;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.WryqfG_dbIcon img {
  max-width: 14px;
  max-height: 14px;
}

.bEVVvq_chipsStatus {
  box-sizing: border-box;
  width: 75px;
  height: 32px;
  background-color: #e6fce5;
  border: 1px solid #bbf2be;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.bEVVvq_chipsStatus span.bEVVvq_nested {
  margin: 0;
}

.bEVVvq_chipsStatus.bEVVvq_error {
  background-color: #ffe6e7;
  border: 1px solid #e4315f;
}

.bEVVvq_chipsStatus.bEVVvq_deleted {
  color: #e4315f;
  background-color: #ffe6e7;
  border: 1px solid #f9c0c1;
}

.bEVVvq_chipsStatus.bEVVvq_user {
  background-color: #f9ead4;
  border: 1px solid #edcea1;
}

.bEVVvq_chipsStatus.bEVVvq_invite, .bEVVvq_chipsStatus.bEVVvq_draft {
  background-color: #dfebff;
  border: 1px solid #b6caeb;
}

.bEVVvq_chipsStatus.bEVVvq_input {
  background-color: #f8f8f9;
  border: 1px solid #f8f8f9;
}

.M2PSTG_page {
  width: calc(100% - 200px);
}

.M2PSTG_capitalized {
  text-transform: capitalize;
}

.M2PSTG_uppercased {
  text-transform: uppercase;
}

.M2PSTG_successText {
  color: #3fb245;
}

.M2PSTG_errorText {
  color: #e4315f;
}

.M2PSTG_verticalLine {
  height: 20px;
  border-left: 1px solid #e8e9ed;
}

.M2PSTG_header {
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 22px 20px;
  display: flex;
}

.M2PSTG_header .M2PSTG_headerTitleGroup {
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  gap: 12px;
  display: flex;
}

.M2PSTG_header .M2PSTG_headerButtonGroup {
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  display: flex;
}

.M2PSTG_header .M2PSTG_headerTitle {
  font-size: 28px;
}

.M2PSTG_header .M2PSTG_headerSubTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}

.M2PSTG_header .M2PSTG_headerSubTitleCounter {
  color: #93939f;
  font-size: 15px;
}

.M2PSTG_header .M2PSTG_headerDescription {
  color: #93939f;
  font-size: 14px;
}

.M2PSTG_table, .M2PSTG_table table {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.M2PSTG_table table tbody {
  height: calc(100vh - 119px);
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: auto;
}

.M2PSTG_table table tbody::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.M2PSTG_table table .M2PSTG_hideScroll {
  overflow: hidden;
}

.M2PSTG_table table thead, .M2PSTG_table table tbody {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.M2PSTG_table table thead tr, .M2PSTG_table table tbody tr {
  width: 100%;
  display: flex;
}

.M2PSTG_table table thead th, .M2PSTG_table table tbody th {
  cursor: pointer;
  color: #93939f;
}

.M2PSTG_table table thead td:last-child, .M2PSTG_table table tbody td:last-child {
  align-items: center;
  display: flex;
}

.M2PSTG_table table thead th, .M2PSTG_table table thead td, .M2PSTG_table table tbody th, .M2PSTG_table table tbody td {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.M2PSTG_table table thead th span, .M2PSTG_table table thead td span, .M2PSTG_table table tbody th span, .M2PSTG_table table tbody td span {
  margin-right: 8px;
}

.M2PSTG_table table thead th:first-child, .M2PSTG_table table thead td:first-child, .M2PSTG_table table tbody th:first-child, .M2PSTG_table table tbody td:first-child {
  padding-left: 20px;
}

.M2PSTG_table table thead th:last-child, .M2PSTG_table table thead td:last-child, .M2PSTG_table table tbody th:last-child, .M2PSTG_table table tbody td:last-child {
  padding-right: 20px;
}

.M2PSTG_table table thead tr {
  border-top: 1px solid #e8e9ed;
  border-bottom: 1px solid #e8e9ed;
  padding: 8px 0;
}

.M2PSTG_table table tbody tr {
  cursor: pointer;
  min-height: 57px;
  box-sizing: border-box;
  border-bottom: 1px solid #e8e9ed;
  padding: 12px 0;
}

.M2PSTG_table table tbody tr .M2PSTG_controlIcons, .M2PSTG_table table tbody tr .M2PSTG_selectedIcons {
  display: none;
}

.M2PSTG_table table tbody tr .M2PSTG_controlIconsHideList {
  color: #93939f;
}

.M2PSTG_table table tbody tr .M2PSTG_controlIconsHideList > span {
  white-space: pre-wrap;
}

.M2PSTG_table table tbody tr:hover {
  background-color: #f6f6ff;
}

.M2PSTG_table table tbody tr:hover td:last-child {
  justify-content: flex-start;
}

.M2PSTG_table table tbody tr:hover .M2PSTG_controlIcons {
  flex-direction: row;
  gap: 16px;
  display: flex;
}

.M2PSTG_table table tbody tr:hover .M2PSTG_controlIconsHideList {
  display: none;
}

.M2PSTG_table table .M2PSTG_activated {
  background-color: #f2f7ff;
}

.M2PSTG_table table .M2PSTG_activated:before {
  content: "";
  width: 2px;
  height: 100%;
  background: #4149f7;
  border-radius: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.M2PSTG_table table .M2PSTG_selected {
  background-color: #f1f4ff;
}

.M2PSTG_table table .M2PSTG_selected:hover .M2PSTG_selectedIcons {
  flex-direction: row;
  gap: 16px;
  display: flex;
}

.M2PSTG_table table .M2PSTG_connectionTableRowConnections {
  grid-template-rows: auto;
  grid-template-columns: 36px 19fr 11fr 11fr 11fr 8fr 180px;
  gap: 10px;
  display: grid;
}

.M2PSTG_table table .M2PSTG_connectionTableRowConnections th:nth-child(2) {
  margin-left: 32px;
}

.M2PSTG_table table .M2PSTG_connectionTableRowConnections td {
  overflow: hidden;
}

.M2PSTG_table table .M2PSTG_connectionTableRowConnections td:nth-child(2) > svg {
  min-width: 24px;
  min-height: 24px;
}

.M2PSTG_table table .M2PSTG_keysTableRowKeys {
  grid-template-rows: auto;
  grid-template-columns: 36px 21fr 28fr 11fr 8fr 180px;
  gap: 10px;
  display: grid;
}

.M2PSTG_table table .M2PSTG_keysTableRowKeys th:nth-child(2) {
  margin-left: 32px;
}

.M2PSTG_table table .M2PSTG_keysTableRowKeys td {
  overflow: hidden;
}

.M2PSTG_table table .M2PSTG_keysTableRowKeys td:nth-child(2) > svg {
  min-width: 24px;
  min-height: 24px;
}

.M2PSTG_table table .M2PSTG_projectsTableRowKeys {
  grid-template-rows: auto;
  grid-template-columns: 21fr 28fr 11fr 8fr 180px;
  gap: 10px;
  display: grid;
}

.M2PSTG_table table .M2PSTG_projectsTableRowKeys td {
  overflow: hidden;
}

.M2PSTG_table table .M2PSTG_projectsTableRowKeys td:nth-child(2) > svg {
  min-width: 24px;
  min-height: 24px;
}

.M2PSTG_table table tbody .M2PSTG_connectionTableRowConnections, .M2PSTG_table table tbody .M2PSTG_keysTableRowKeys, .M2PSTG_table table tbody .M2PSTG_projectsTableRowKeys {
  position: relative;
}

.M2PSTG_table table .M2PSTG_connectionTableRowEndpoints {
  grid-template-rows: auto;
  grid-template-columns: 36px 15fr 6fr 15fr 12fr 11fr 8fr 180px;
  gap: 10px;
  display: grid;
}

.M2PSTG_table table .M2PSTG_connectionTableRowEndpoints th:nth-child(2) {
  margin-left: 32px;
}

.M2PSTG_table table .M2PSTG_connectionTableRowEndpoints td {
  overflow: hidden;
}

.M2PSTG_table table .M2PSTG_connectionTableRowEndpoints td:nth-child(2) > svg {
  min-width: 24px;
  min-height: 24px;
}

.M2PSTG_table table tbody .M2PSTG_connectionTableRowEndpoints {
  position: relative;
}

.M2PSTG_table table .M2PSTG_connectionTableRowTrash {
  grid-template-rows: auto;
  grid-template-columns: 36px 20fr 8fr 8fr 12fr 10fr 8fr 180px;
  gap: 10px;
  display: grid;
}

.M2PSTG_table table .M2PSTG_connectionTableRowTrash th:nth-child(2) {
  margin-left: 32px;
}

.M2PSTG_table table .M2PSTG_connectionTableRowTrash .M2PSTG_aligned {
  justify-self: flex-start;
  margin: -1px 0;
  padding: 1px 0;
}

.M2PSTG_table table .M2PSTG_connectionTableRowTrash th:last-child {
  justify-self: flex-start;
}

.M2PSTG_table table .M2PSTG_connectionTableRowTrash td {
  overflow: hidden;
}

.M2PSTG_table table .M2PSTG_connectionTableRowTrash td:nth-child(2) > svg {
  min-width: 24px;
  min-height: 24px;
}

.M2PSTG_table table tbody .M2PSTG_connectionTableRowTrash {
  position: relative;
}

.M2PSTG_table table.M2PSTG_apiTable thead th span, .M2PSTG_table table.M2PSTG_apiTable thead td span, .M2PSTG_table table.M2PSTG_apiTable tbody th span, .M2PSTG_table table.M2PSTG_apiTable tbody td span, .M2PSTG_table table.M2PSTG_connectionTable thead th span, .M2PSTG_table table.M2PSTG_connectionTable thead td span, .M2PSTG_table table.M2PSTG_connectionTable tbody th span, .M2PSTG_table table.M2PSTG_connectionTable tbody td span, .M2PSTG_table table.M2PSTG_usersTable thead th span, .M2PSTG_table table.M2PSTG_usersTable thead td span, .M2PSTG_table table.M2PSTG_usersTable tbody th span, .M2PSTG_table table.M2PSTG_usersTable tbody td span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.M2PSTG_table .M2PSTG_emptyContent {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 164px;
  display: flex;
}

.M2PSTG_table .M2PSTG_emptyContent .M2PSTG_iconWrap {
  width: 180px;
  height: 180px;
  cursor: pointer;
  background-color: #f2f7ff;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  display: flex;
}

.M2PSTG_table .M2PSTG_emptyContent .M2PSTG_iconWrap svg {
  width: 120px;
  height: 120px;
}

.M2PSTG_table .M2PSTG_emptyContent .M2PSTG_iconWrap svg * {
  stroke: #4149f7;
}

.M2PSTG_table .M2PSTG_emptyContent .M2PSTG_caption {
  margin-bottom: 8px;
  font-weight: 600;
}

.M2PSTG_table .M2PSTG_emptyContent .M2PSTG_action {
  margin-top: 8px;
}

.M2PSTG_table .M2PSTG_emptyContent a {
  background-color: inherit;
  cursor: pointer;
  color: #4149f7;
  border: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  text-decoration: none;
  display: inline-block;
}

.M2PSTG_body {
  min-height: calc(100vh - 84px);
  max-height: calc(100vh - 84px);
  border-top: 1px solid #e8e9ed;
  flex-direction: row;
  display: flex;
  position: relative;
  overflow-y: hidden;
}

.M2PSTG_body.M2PSTG_vertical {
  flex-direction: column;
}

.M2PSTG_body.M2PSTG_vertical .M2PSTG_mainContent {
  flex-grow: initial;
}

.M2PSTG_body .M2PSTG_sectionHeader {
  font-size: 18px;
  font-weight: 600;
}

.M2PSTG_body .M2PSTG_sectionHeader > :last-child {
  color: #93939f;
  margin-left: 6px;
  font-size: 14px;
}

.M2PSTG_body .M2PSTG_clickable:hover {
  cursor: pointer;
}

.M2PSTG_body .M2PSTG_passive {
  color: #bfc1ca;
}

.M2PSTG_body .M2PSTG_errorState {
  color: #e4315f;
}

.M2PSTG_body .M2PSTG_passive.M2PSTG_errorState {
  color: #f9c0c1;
}

.M2PSTG_body .M2PSTG_sectionContainer {
  grid-template-rows: auto;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  margin-top: 20px;
  display: grid;
}

.M2PSTG_body .M2PSTG_sectionContainer.M2PSTG_triple {
  grid-template-columns: 94px 2fr 3fr 94px 74px 50px;
}

.M2PSTG_body .M2PSTG_sectionContainer.M2PSTG_tripleAlternate {
  grid-template-columns: 2fr 2fr 3fr 74px 50px;
}

.M2PSTG_body .M2PSTG_sectionContainer .M2PSTG_testConnectionBtn {
  display: flex;
}

.M2PSTG_body .M2PSTG_sectionContainer .M2PSTG_testConnectionBtn > button {
  width: 100%;
  height: 36px;
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 0;
}

.M2PSTG_body .M2PSTG_sectionContainer .M2PSTG_inputWrapper {
  flex-direction: column;
  display: flex;
  position: relative;
}

.M2PSTG_body .M2PSTG_sectionContainer .M2PSTG_inputWrapper.M2PSTG_mergeColumns {
  grid-column: span 2;
}

.M2PSTG_body .M2PSTG_sectionContainer .M2PSTG_inputWrapper .M2PSTG_errorPlaceholder {
  color: #e4315f;
  font-size: 11px;
  position: absolute;
  bottom: -15px;
  left: 0;
}

.M2PSTG_body .M2PSTG_sectionContainer .M2PSTG_inputWrapper .M2PSTG_inputTabs {
  flex-direction: row;
  gap: 16px;
  display: flex;
}

.M2PSTG_body .M2PSTG_sectionContainer .M2PSTG_inputWrapper .M2PSTG_inputTabs > * {
  cursor: pointer;
}

.M2PSTG_body .M2PSTG_sectionContainer .M2PSTG_inputWrapper .M2PSTG_codeEditorWrap > div {
  height: 30px;
  padding: 4px 2px 0 6px;
  overflow: auto;
}

.M2PSTG_body .M2PSTG_sectionContainer .M2PSTG_inputWrapper .M2PSTG_codeEditorWrap > div > :first-child {
  height: 31px;
}

.M2PSTG_body .M2PSTG_sectionContainer .M2PSTG_inputWrapper .M2PSTG_codeEditorWrap > div > :first-child > div {
  height: 100%;
  padding-right: 34px;
}

.M2PSTG_body .M2PSTG_sectionContainer .M2PSTG_inputWrapper .M2PSTG_codeEditorWrap > div div {
  outline: none;
}

.M2PSTG_body .M2PSTG_sectionContainer .M2PSTG_inputWrapper .M2PSTG_codeEditorWrap > div > :last-child {
  bottom: 10px;
}

.M2PSTG_body .M2PSTG_sectionContainer .M2PSTG_inputWrapper .M2PSTG_codeEditorWrapInline > div > :last-child {
  bottom: 9px;
}

.M2PSTG_body .M2PSTG_sectionContainer .M2PSTG_inputWrapper.M2PSTG_btn {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.M2PSTG_body .M2PSTG_sectionContainer .M2PSTG_actionParamContainer {
  align-items: flex-end;
  display: flex;
  position: relative;
}

.M2PSTG_body .M2PSTG_sectionContainer .M2PSTG_actionParamContainer .M2PSTG_actionParam {
  position: absolute;
  bottom: 10px;
}

.M2PSTG_body .M2PSTG_sectionContainer .M2PSTG_actionParamContainer .M2PSTG_errorConnection {
  color: #e4315f;
  margin-right: 8px;
}

.M2PSTG_body .M2PSTG_sectionContainer .M2PSTG_inputLabel {
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  display: flex;
}

.M2PSTG_body .M2PSTG_sectionContainer .M2PSTG_inputLabel.M2PSTG_flexBetween {
  justify-content: space-between;
}

.M2PSTG_body .M2PSTG_sectionContainer .M2PSTG_inputLabel button {
  color: #080828;
}

.M2PSTG_body .M2PSTG_sectionContainer .M2PSTG_inputLabelDetails {
  color: #93939f;
  white-space: pre;
}

.M2PSTG_body .M2PSTG_sectionContainer .M2PSTG_inputLabelDisabled {
  color: #93939f;
}

.M2PSTG_body .M2PSTG_sectionContainer.M2PSTG_wide {
  max-width: calc(100vw - 603px);
  min-width: 400px;
  grid-template-columns: 1fr;
}

.M2PSTG_body .M2PSTG_sectionContainer.M2PSTG_wide .M2PSTG_inputWrapper {
  width: 100%;
  max-width: calc(100vw - 603px);
  overflow: hidden;
}

.M2PSTG_body .M2PSTG_limitSection {
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
  display: flex;
}

.M2PSTG_body .M2PSTG_limitSection .M2PSTG_inputWrapper {
  flex-direction: column;
  gap: 8px;
  display: flex;
  position: relative;
}

.M2PSTG_body .M2PSTG_limitSection .M2PSTG_inputWrapper .M2PSTG_errorPlaceholder {
  text-wrap: nowrap;
  color: #e4315f;
  font-size: 11px;
  position: absolute;
  bottom: -17px;
  left: 0;
}

.M2PSTG_body .M2PSTG_sectionRightsWrap {
  border-top: 1px solid #e8e9ed;
  margin-top: 20px;
  padding-top: 20px;
}

.M2PSTG_body .M2PSTG_sectionRightsWrap .M2PSTG_sectionHeader {
  margin-bottom: 20px;
}

.M2PSTG_body .M2PSTG_sectionRights {
  flex-direction: column;
  display: flex;
}

.M2PSTG_body .M2PSTG_sectionRights .M2PSTG_rightsList {
  margin-bottom: 40px;
  display: flex;
}

.M2PSTG_body .M2PSTG_sectionRights .M2PSTG_checkboxWrap {
  margin-right: 40px;
}

.M2PSTG_body .M2PSTG_sectionRights .M2PSTG_inputLabel {
  margin-bottom: 20px;
  font-weight: 600;
  display: block;
}

.M2PSTG_body .M2PSTG_sidepanel {
  min-height: calc(100vh - 84px);
  max-height: calc(100vh - 84px);
  box-sizing: border-box;
  min-width: 360px;
  background-color: #fbfcff;
  border-left: 1px solid #e8e9ed;
  flex-direction: column;
  flex-basis: 360px;
  display: flex;
}

.M2PSTG_body .M2PSTG_sidepanelGroup {
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  margin: 0 20px;
  display: flex;
  overflow: hidden;
}

.M2PSTG_body .M2PSTG_sidepanelGroup:nth-child(2) {
  border-top: 1px solid #e8e9ed;
}

.M2PSTG_body .M2PSTG_sidepanelHeading {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px 0 0;
  display: flex;
  position: relative;
}

.M2PSTG_body .M2PSTG_sidepanelHeading .M2PSTG_subHeaders {
  gap: 20px;
  display: flex;
}

.M2PSTG_body .M2PSTG_sidepanelHeading .M2PSTG_addButton {
  color: #4149f7;
}

.M2PSTG_body .M2PSTG_sidepanelHeading .M2PSTG_textAction {
  position: absolute;
  top: 50%;
  right: 0;
}

.M2PSTG_body .M2PSTG_chipsStatus {
  width: 90px;
  justify-content: left;
  padding-left: 12px;
}

.M2PSTG_body .M2PSTG_sidepanelTable {
  height: 100%;
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  overflow: hidden;
}

.M2PSTG_body .M2PSTG_sidepanelTable hr {
  border: 0;
  border-top: 1px solid #e8e9ed;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableHeading, .M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody {
  border-bottom: 1px solid #e8e9ed;
  flex-direction: row;
  padding-bottom: 10px;
  display: flex;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableHeading .M2PSTG_line, .M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody .M2PSTG_line {
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableHeading .M2PSTG_line > div:nth-child(1), .M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody .M2PSTG_line > div:nth-child(1) {
  max-width: 272px;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  overflow: hidden;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableHeading .M2PSTG_line > div:nth-child(2), .M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody .M2PSTG_line > div:nth-child(2) {
  max-width: 72px;
  flex-basis: 72px;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableHeading .M2PSTG_line > div:nth-child(3), .M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody .M2PSTG_line > div:nth-child(3) {
  max-width: 90px;
  text-align: right;
  margin-right: 2px;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableHeading .M2PSTG_activeVersion, .M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody .M2PSTG_activeVersion {
  cursor: pointer;
  background-color: #dfebff;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableHeading .M2PSTG_lineHeader, .M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody .M2PSTG_lineHeader {
  color: #93939f;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  display: flex;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableHeading .M2PSTG_lineHeader > div:nth-child(1), .M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody .M2PSTG_lineHeader > div:nth-child(1) {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  margin-right: 18px;
  overflow: hidden;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableHeading .M2PSTG_lineHeader > div:nth-child(2), .M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody .M2PSTG_lineHeader > div:nth-child(2) {
  width: 4px;
  min-width: 4px;
  max-width: 4px;
  flex-basis: 4px;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableHeading .M2PSTG_lineHeader > div:nth-child(3), .M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody .M2PSTG_lineHeader > div:nth-child(3) {
  max-width: 90px;
  text-align: left;
  margin-right: 2px;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableHeading .M2PSTG_lineHeader > div:nth-child(4), .M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody .M2PSTG_lineHeader > div:nth-child(4) {
  margin: auto;
  padding-top: 6px;
  padding-left: 8px;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableHeading .M2PSTG_historyLine, .M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody .M2PSTG_historyLine {
  box-sizing: border-box;
  width: 100%;
  height: 44px;
  min-height: 44px;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableHeading .M2PSTG_historyLine.M2PSTG_activeCommit, .M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody .M2PSTG_historyLine.M2PSTG_activeCommit {
  background-color: #dfebff;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableHeading .M2PSTG_historyLine .M2PSTG_bold, .M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody .M2PSTG_historyLine .M2PSTG_bold {
  font-weight: 700;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableHeading .M2PSTG_historyLine .M2PSTG_arrowUp, .M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody .M2PSTG_historyLine .M2PSTG_arrowUp {
  transition-duration: .3s;
  transform: rotate(0deg);
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableHeading .M2PSTG_historyLine .M2PSTG_arrowDown, .M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody .M2PSTG_historyLine .M2PSTG_arrowDown {
  transition-duration: .3s;
  transform: rotate(-90deg);
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableHeading .M2PSTG_historyLine > div:nth-child(1), .M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody .M2PSTG_historyLine > div:nth-child(1) {
  max-width: 272px;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  overflow: hidden;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableHeading .M2PSTG_historyLine > div:nth-child(2), .M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody .M2PSTG_historyLine > div:nth-child(2) {
  max-width: 4px;
  min-width: 4px;
  width: 4px;
  flex-basis: 4px;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableHeading .M2PSTG_historyLine > div:nth-child(3), .M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody .M2PSTG_historyLine > div:nth-child(3) {
  max-width: 90px;
  text-align: center;
  margin-right: 2px;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableHeading .M2PSTG_historyLine > div:nth-child(4), .M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody .M2PSTG_historyLine > div:nth-child(4) {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  display: flex;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableHeading .M2PSTG_historyLine:hover .M2PSTG_historyLineControls:not(.M2PSTG_historyLineControlsDisabled), .M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody .M2PSTG_historyLine:hover .M2PSTG_historyLineControls:not(.M2PSTG_historyLineControlsDisabled) {
  width: 44px;
  min-width: 44px;
  padding-left: 8px;
  transition: all .3s;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableHeading .M2PSTG_historyLineControls, .M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody .M2PSTG_historyLineControls {
  min-width: 0;
  width: 0;
  flex-direction: row;
  gap: 12px;
  padding-left: 0;
  transition: all .3s;
  display: flex;
  overflow: hidden;
  flex-grow: unset !important;
  margin: 0 !important;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_commitSection {
  background-color: #f1f4ff;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_commitSection > li {
  flex-direction: column;
  display: flex;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_commitSection .M2PSTG_commitActiveBtn {
  background-color: #f1f4ff;
  padding: 20px;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_commitSection .M2PSTG_overrideActionContainer {
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 12px;
  display: flex;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBodyContainer {
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  overflow: hidden;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody {
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 100%;
  flex-direction: column;
  padding-top: 8px;
  display: flex;
  overflow-y: auto;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody .M2PSTG_line {
  border-bottom: 1px solid #e8e9ed;
  padding-top: 12px;
  padding-bottom: 12px;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBody .M2PSTG_line:last-child {
  border-bottom: none;
}

.M2PSTG_body .M2PSTG_sidepanelTable .M2PSTG_sidepanelTableBodyDivider {
  margin: 20px 0;
}

.M2PSTG_body .M2PSTG_sidepanelForm {
  height: 100%;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-direction: column;
  display: flex;
  position: relative;
  overflow-y: auto;
}

.M2PSTG_body .M2PSTG_sidepanelForm::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.M2PSTG_body .M2PSTG_mainContent {
  min-height: calc(100vh - 84px);
  max-height: calc(100vh - 84px);
  min-width: 400px;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-grow: 1;
  padding: 20px;
  overflow-y: scroll;
}

.M2PSTG_body .M2PSTG_mainContent::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.M2PSTG_body .M2PSTG_mainContent.M2PSTG_mainContentUsers {
  min-height: unset;
  max-height: unset;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: visible;
}

.M2PSTG_body .M2PSTG_mainContent.M2PSTG_mainContentUsers::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.M2PSTG_body .M2PSTG_mainContent.M2PSTG_mainContentUsers .M2PSTG_sectionContainer {
  grid-template-columns: 1fr 1fr 1fr;
}

.M2PSTG_body .M2PSTG_mainContent .M2PSTG_section {
  flex-direction: column;
  display: flex;
}

.M2PSTG_body .M2PSTG_mainContent .M2PSTG_section .M2PSTG_subHeaders {
  flex-direction: row;
  align-items: center;
  gap: 24px;
  display: flex;
}

.M2PSTG_body .M2PSTG_mainContent hr {
  border: 0;
  border-top: 1px solid #e8e9ed;
  margin-top: 20px;
  margin-bottom: 20px;
}

.M2PSTG_body .M2PSTG_bottomPanel {
  border-top: 1px solid #e8e9ed;
  flex-direction: row;
  flex-grow: 1;
  display: flex;
}

.M2PSTG_body .M2PSTG_bottomPanel .M2PSTG_vertical {
  width: 0;
  border-right: 1px solid #e8e9ed;
}

.M2PSTG_body .M2PSTG_bottomPanel .M2PSTG_section {
  box-sizing: border-box;
  width: calc(50% - 1px);
  flex-direction: column;
  padding: 20px;
  display: flex;
}

.M2PSTG_body .M2PSTG_bottomPanel .M2PSTG_sidepanelTableBody {
  box-sizing: border-box;
  height: calc(100vh - 415px);
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-bottom: 44px;
  overflow: auto;
}

.M2PSTG_body .M2PSTG_bottomPanel .M2PSTG_sidepanelTableBody::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.M2PSTG_body .M2PSTG_bottomPanel .M2PSTG_sidepanelTableHeading .M2PSTG_line a, .M2PSTG_body .M2PSTG_bottomPanel .M2PSTG_sidepanelTableBody .M2PSTG_line a {
  color: #4149f7;
  text-decoration: none;
}

.M2PSTG_body .M2PSTG_bottomPanel .M2PSTG_sidepanelTableHeading .M2PSTG_line > div:nth-child(1), .M2PSTG_body .M2PSTG_bottomPanel .M2PSTG_sidepanelTableBody .M2PSTG_line > div:nth-child(1) {
  width: calc(100% - 330px);
  max-width: none;
  flex-basis: auto;
}

.M2PSTG_body .M2PSTG_bottomPanel .M2PSTG_sidepanelTableHeading .M2PSTG_line > div:nth-child(2), .M2PSTG_body .M2PSTG_bottomPanel .M2PSTG_sidepanelTableBody .M2PSTG_line > div:nth-child(2) {
  width: 200px;
  max-width: none;
  flex-basis: auto;
  align-items: center;
  display: flex;
}

.M2PSTG_body .M2PSTG_bottomPanel .M2PSTG_sidepanelTableHeading .M2PSTG_line > div:nth-child(3), .M2PSTG_body .M2PSTG_bottomPanel .M2PSTG_sidepanelTableBody .M2PSTG_line > div:nth-child(3) {
  width: 130px;
  max-width: none;
  flex-basis: auto;
}

.M2PSTG_chatGptButtonContainer {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.M2PSTG_chatGptButtonContainer .M2PSTG_btnContainer {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: flex;
  position: relative;
}

.M2PSTG_chatGptButtonContainer .M2PSTG_statusContainer {
  flex-direction: row;
  align-items: center;
  gap: 12px;
  display: flex;
  position: relative;
}

.M2PSTG_contextMenu {
  z-index: 1;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  display: flex;
  position: fixed;
  box-shadow: 0 2px 6px #0808281a;
}

.M2PSTG_contextMenuList {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 8px;
  display: block;
}

.M2PSTG_contextMenuItem {
  position: relative;
}

.M2PSTG_contextMenuButton {
  cursor: pointer;
  font: inherit;
  width: 100%;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 8px;
  align-items: center;
  padding: 8px 36px 8px 8px;
  display: flex;
  position: relative;
}

.M2PSTG_contextMenuButton:hover {
  background-color: #f1f4ff;
}

.M2PSTG_spanWrapper {
  max-height: 20px;
  overflow: hidden;
}

.M2PSTG_compactLayout {
  min-height: calc(100vh - 150px) !important;
  max-height: calc(100vh - 150px) !important;
}

.M2PSTG_borderBottom {
  border-bottom: 1px solid #e8e9ed;
}

.M2PSTG_borderTop {
  border-top: 1px solid #e8e9ed;
}

.M2PSTG_testConnectionContainerGroup {
  flex-flow: row;
  align-items: center;
  display: flex;
}

.M2PSTG_testConnectionContainer {
  flex-flow: row;
  align-items: center;
  gap: 10px;
  display: flex;
}

.qzzeUq_contextMenu {
  z-index: 1;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  display: flex;
  position: fixed;
  box-shadow: 0 2px 6px #0808281a;
}

.qzzeUq_contextMenu .qzzeUq_contextMenuList {
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 0;
  display: block;
}

.qzzeUq_contextMenu .qzzeUq_contextMenuList .qzzeUq_contextMenuItem {
  border-radius: 8px;
  position: relative;
}

.qzzeUq_contextMenu .qzzeUq_contextMenuList .qzzeUq_contextMenuItem .qzzeUq_contextMenuButton {
  cursor: pointer;
  font: inherit;
  width: 100%;
  text-align: left;
  background-color: #fff;
  border: 0;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 8px 36px 8px 8px;
  display: flex;
  position: relative;
}

.qzzeUq_contextMenu .qzzeUq_contextMenuList .qzzeUq_contextMenuItem .qzzeUq_contextMenuButton .qzzeUq_iconContainer {
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.qzzeUq_contextMenu .qzzeUq_contextMenuList .qzzeUq_contextMenuItem .qzzeUq_contextMenuButton:hover {
  background-color: #f1f4ff;
}

.qzzeUq_contextMenu .qzzeUq_contextMenuList .qzzeUq_contextMenuItem .qzzeUq_borderAlignTop, .qzzeUq_contextMenu .qzzeUq_contextMenuList .qzzeUq_contextMenuItem .qzzeUq_borderAlignTop > button {
  border-radius: 8px 8px 0 0;
}

.qzzeUq_contextMenu .qzzeUq_contextMenuList .qzzeUq_contextMenuItem .qzzeUq_borderAlignBottom, .qzzeUq_contextMenu .qzzeUq_contextMenuList .qzzeUq_contextMenuItem .qzzeUq_borderAlignBottom > button {
  border-radius: 0 0 8px 8px;
}

.qzzeUq_contextMenu .qzzeUq_contextMenuList .qzzeUq_contextMenuItem .qzzeUq_divider {
  border: 0;
  border-top: 1px solid #e8e9ed;
}

.RttVZq_token {
  box-sizing: border-box;
  border: 1px solid #b6caeb;
  border-radius: 4px;
  margin: 0 4px;
  padding: 1px 8px;
  background: #dfebff !important;
}

.Fap7eW_inputWrapper {
  position: relative;
}

.Fap7eW_inputWrapper.Fap7eW_inputVariables {
  height: 36px;
}

.Fap7eW_iconLeft {
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  left: 11px;
  transform: translateY(-50%);
}

.Fap7eW_iconRight {
  cursor: pointer;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  right: 11px;
  transform: translateY(-50%);
}

.Fap7eW_iconRight:hover svg * {
  fill: #4149f7;
}

.Fap7eW_iconRight.Fap7eW_iconRightMultiline {
  top: 11px;
  transform: translateY(0);
}

.Fap7eW_iconRightStatus {
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  right: 11px;
  transform: translateY(-50%);
}

.Fap7eW_isPassword {
  cursor: pointer;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.Fap7eW_isPassword:hover svg * {
  stroke: #4149f7;
}

input {
  outline: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #f8f8f9 !important;
}

.Fap7eW_input {
  width: 100%;
  box-sizing: border-box;
  color: #080828;
  background-color: #f8f8f9;
  border: 1px solid #f8f8f9;
  border-radius: 4px;
  outline: none;
  padding: 0 12px;
  font-size: 14px;
  line-height: 34px;
}

.Fap7eW_input:disabled:hover {
  background-color: #f8f8f9 !important;
  border: 1px solid #f8f8f9 !important;
}

.Fap7eW_input.Fap7eW_filled:hover {
  border: 1px solid #f8f8f9;
}

.Fap7eW_input.Fap7eW_filled:focus {
  background-color: #fff;
  border: 1px solid #4149f7;
}

.Fap7eW_input::placeholder {
  color: #93939f;
}

.Fap7eW_input:hover {
  background-color: #f6f6ff;
  border: 1px solid #4149f7;
}

.Fap7eW_input:focus {
  background-color: #fff;
  border: 1px solid #4149f7;
}

.Fap7eW_input.Fap7eW_error {
  background-color: #fef4f4 !important;
  border: 1px solid #e4315f !important;
}

.Fap7eW_input svg * {
  fill: #fff;
}

.Fap7eW_input.Fap7eW_leftPadding {
  padding-left: 36px;
}

.Fap7eW_input.Fap7eW_rightPadding {
  padding-right: 36px;
}

.Fap7eW_fakePassword {
  text-security: disc;
  -webkit-text-security: disc;
  -moz-text-security: disc;
}

.text-input {
  border: 2px solid gray;
  border-radius: 6px;
  padding: 4px 6px;
  font-family: Helvetica, Arial, sans-serif;
}

.text-input:focus {
  border-color: #6495ed;
  outline: none;
}

.text-input p {
  margin: 0;
  padding: 0;
}

.se2g8q_input * {
  white-space: nowrap;
}

.se2g8q_input {
  width: 100%;
  box-sizing: border-box;
  color: #080828;
  background-color: #f8f8f9;
  border: 1px solid #f8f8f9;
  border-radius: 4px;
  outline: none;
  padding: 0;
  font-size: 14px;
  line-height: 34px;
  position: absolute;
  top: 0;
  left: 0;
}

.se2g8q_input:disabled:hover {
  background-color: #f8f8f9 !important;
  border: 1px solid #f8f8f9 !important;
}

.se2g8q_input.se2g8q_filled:hover {
  border: 1px solid #f8f8f9;
}

.se2g8q_input.se2g8q_filled:focus {
  background-color: #fff;
  border: 1px solid #4149f7;
}

.se2g8q_input::placeholder {
  color: #93939f;
}

.se2g8q_input:hover {
  background-color: #f6f6ff;
  border: 1px solid #4149f7;
}

.se2g8q_input.se2g8q_focused, .se2g8q_input.se2g8q_filled.se2g8q_focused, .se2g8q_input:focus {
  background-color: #fff;
  border: 1px solid #4149f7;
}

.se2g8q_input.se2g8q_error {
  background-color: #fef4f4 !important;
  border: 1px solid #e4315f !important;
}

.se2g8q_input svg * {
  fill: #fff;
}

.se2g8q_input.se2g8q_leftPadding {
  padding-left: 36px;
}

.se2g8q_input.se2g8q_rightPadding {
  padding-right: 36px;
}

.se2g8q_input2 {
  width: 100%;
  box-sizing: border-box;
  color: #080828;
  cursor: text;
  background-color: #f8f8f9;
  border: none;
  border-radius: 4px;
  outline: none;
  padding: 0 12px;
  font-size: 14px;
  line-height: 34px;
}

.se2g8q_input2:disabled:hover {
  background-color: #f8f8f9 !important;
}

.se2g8q_input2.se2g8q_filled:focus {
  background-color: #fff;
}

.se2g8q_input2::placeholder {
  color: #93939f;
}

.se2g8q_input2:hover {
  background-color: #f6f6ff;
}

.se2g8q_input2:focus {
  background-color: #fff;
}

.se2g8q_input2.se2g8q_error {
  background-color: #fef4f4 !important;
}

.se2g8q_input2 svg * {
  fill: #fff;
}

.se2g8q_input2.se2g8q_leftPadding {
  padding-left: 36px;
}

.se2g8q_input2.se2g8q_rightPadding {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 36px;
}

[data-placeholder]:empty:before {
  content: attr(data-placeholder);
  color: #93939f;
}

.se2g8q_suggestion {
  cursor: pointer;
  border-radius: 4px;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  display: flex;
}

.se2g8q_suggestion:hover {
  background-color: #f6f6ff;
}

.se2g8q_suggestion:before {
  width: 16px;
  height: 16px;
  background-image: url("suggestionIcon.b05e81d1.png");
  background-size: 16px 16px;
  margin-top: 3px;
  display: block;
  content: " " !important;
}

.se2g8q_suggestions {
  z-index: 999;
  min-width: 200px;
  background-color: #fff;
  border: 1px solid #e8e9ed;
  border-radius: 4px;
  padding: 3px;
  position: absolute;
}

.se2g8q_selectedSuggestion {
  background-color: #f6f6ff;
}

[contenteditable="true"].single-line {
  white-space: nowrap;
  overflow: hidden;
}

[contenteditable="true"].single-line * {
  white-space: nowrap;
}

@keyframes GbkFMq_zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.5, .5, .5);
  }

  50% {
    opacity: 1;
  }

  70% {
    transform: scale3d(1.01, 1.01, 1.01);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.GbkFMq_modal {
  z-index: 9999;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #000000b3;
  padding-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.GbkFMq_modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.GbkFMq_modalContent {
  width: 70%;
  max-width: 640px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  animation: GbkFMq_zoomIn .4s cubic-bezier(.68, -.55, .27, 1.55) both;
}

.GbkFMq_header {
  border-bottom: 1px solid #e8e9ed;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  display: flex;
}

.GbkFMq_header .GbkFMq_title {
  font-weight: 600;
}

.GbkFMq_body {
  background-color: #fbfcff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px 20px;
  display: flex;
}

.GbkFMq_body .GbkFMq_descriptionContent {
  flex-direction: column;
  gap: 8px;
  padding-top: 12px;
  padding-bottom: 46px;
  display: flex;
}

.GbkFMq_body .GbkFMq_descriptionContent strong {
  font-weight: 600;
}

.GbkFMq_body .GbkFMq_mainContent {
  padding: 16px 0 32px;
  display: flex;
  position: relative;
}

.GbkFMq_body .GbkFMq_mainContent > div {
  width: 100%;
}

.GbkFMq_body .GbkFMq_mainContent .GbkFMq_inviteLabel {
  font-weight: 600;
  position: absolute;
  top: 17px;
  left: 0;
}

.GbkFMq_body .GbkFMq_mainContent .GbkFMq_selectRole {
  width: 90px;
  position: absolute;
  top: 8px;
  right: 12px;
}

.GbkFMq_body .GbkFMq_mainContent .GbkFMq_inputWrapper {
  width: 100%;
  flex-flow: wrap;
  gap: 8px;
  display: flex;
}

.GbkFMq_body .GbkFMq_buttonGroup {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.GbkFMq_body .GbkFMq_buttonGroupCol {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

@keyframes S-Krha_zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.5, .5, .5);
  }

  50% {
    opacity: 1;
  }

  70% {
    transform: scale3d(1.01, 1.01, 1.01);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.S-Krha_modal {
  z-index: 9999;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #000000b3;
  padding-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.S-Krha_modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.S-Krha_modalContent {
  width: 70%;
  max-width: 640px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  animation: S-Krha_zoomIn .4s cubic-bezier(.68, -.55, .27, 1.55) both;
}

.S-Krha_header {
  border-bottom: 1px solid #e8e9ed;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  display: flex;
}

.S-Krha_header .S-Krha_title {
  font-weight: 600;
}

.S-Krha_body {
  background-color: #fbfcff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px 20px;
  display: flex;
}

.S-Krha_body .S-Krha_descriptionContent {
  flex-direction: column;
  gap: 8px;
  padding-top: 12px;
  padding-bottom: 46px;
  display: flex;
}

.S-Krha_body .S-Krha_descriptionContent strong {
  font-weight: 600;
}

.S-Krha_body .S-Krha_mainContent {
  padding: 16px 0 32px;
  display: flex;
  position: relative;
}

.S-Krha_body .S-Krha_mainContent > div {
  width: 100%;
}

.S-Krha_body .S-Krha_mainContent .S-Krha_inviteLabel {
  font-weight: 600;
  position: absolute;
  top: 17px;
  left: 0;
}

.S-Krha_body .S-Krha_mainContent .S-Krha_selectRole {
  width: 90px;
  position: absolute;
  top: 8px;
  right: 12px;
}

.S-Krha_body .S-Krha_mainContent .S-Krha_inputWrapper {
  width: 100%;
  flex-flow: wrap;
  gap: 8px;
  display: flex;
}

.S-Krha_body .S-Krha_buttonGroup {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.S-Krha_body .S-Krha_buttonGroupCol {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

.exGO1q_breaadscrumbContainer {
  color: #080828;
  flex-direction: row;
  gap: 32px;
  font-size: 14px;
  line-height: 38px;
  display: flex;
  position: relative;
}

.exGO1q_breadscrumbItem {
  position: relative;
}

.exGO1q_arrow {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: -28px;
  transform: translateY(-50%);
}

@keyframes ygt-2a_zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.5, .5, .5);
  }

  50% {
    opacity: 1;
  }

  70% {
    transform: scale3d(1.01, 1.01, 1.01);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.ygt-2a_modal {
  z-index: 9999;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #000000b3;
  padding-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.ygt-2a_modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.ygt-2a_modalContent {
  width: 70%;
  max-width: 640px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  animation: ygt-2a_zoomIn .4s cubic-bezier(.68, -.55, .27, 1.55) both;
}

.ygt-2a_header {
  border-bottom: 1px solid #e8e9ed;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  display: flex;
}

.ygt-2a_header .ygt-2a_title {
  font-weight: 600;
}

.ygt-2a_body {
  background-color: #fbfcff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px 20px;
  display: flex;
}

.ygt-2a_body .ygt-2a_mainContent {
  padding: 0 0 46px;
  display: flex;
}

.ygt-2a_body .ygt-2a_modelTitle {
  font-weight: 700;
}

.ygt-2a_body .ygt-2a_buttonGroup {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

.QCLrPW_chipsAction {
  box-sizing: border-box;
  background-color: #dfebff;
  border: 1px solid #b6caeb;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 6px 12px 7px;
  display: flex;
  position: relative;
  top: -10px;
}

.QCLrPW_chipsAction span.QCLrPW_nested {
  margin: 0 12px 0 0;
}

.QCLrPW_chipsAction.QCLrPW_name {
  background-color: #f9ead4;
  border: 1px solid #e4b879;
}

.BDHbBq_select {
  width: 100%;
  position: relative;
}

.BDHbBq_select.BDHbBq_active.BDHbBq_error button {
  background-color: #fef4f4;
  border: 1px solid #e4315f;
}

.BDHbBq_select.BDHbBq_active button {
  border: 1px solid #4149f7;
}

.BDHbBq_select.BDHbBq_active button:hover:not(:disabled) {
  background-color: #f8f8f9;
}

.BDHbBq_select.BDHbBq_active button:disabled {
  text-align: center;
  padding-left: 12px;
  padding-right: 12px;
}

.BDHbBq_select.BDHbBq_active button .BDHbBq_selectAngle {
  transform: translateY(-40%)rotate(-180deg);
}

.BDHbBq_select.BDHbBq_text.BDHbBq_active button, .BDHbBq_select.BDHbBq_textColor.BDHbBq_active button {
  border: none;
}

.BDHbBq_select.BDHbBq_text button, .BDHbBq_select.BDHbBq_textColor button {
  cursor: pointer;
  height: auto;
  background-color: inherit;
  border: none;
}

.BDHbBq_select.BDHbBq_text button:focus, .BDHbBq_select.BDHbBq_text button:hover:not(:disabled), .BDHbBq_select.BDHbBq_textColor button:focus, .BDHbBq_select.BDHbBq_textColor button:hover:not(:disabled) {
  background-color: inherit;
  border: none;
}

.BDHbBq_select.BDHbBq_text ul.BDHbBq_options, .BDHbBq_select.BDHbBq_textColor ul.BDHbBq_options {
  top: 28px;
}

.BDHbBq_select.BDHbBq_text ul.BDHbBq_options li, .BDHbBq_select.BDHbBq_textColor ul.BDHbBq_options li {
  padding: 6px 0;
}

.BDHbBq_select.BDHbBq_textColor button {
  color: #4149f7;
}

.BDHbBq_select.BDHbBq_textColor button .BDHbBq_selectAngle * {
  fill: #4149f7;
}

.BDHbBq_select.BDHbBq_textColor ul.BDHbBq_options {
  min-width: 138px;
  right: 0;
}

.BDHbBq_select.BDHbBq_error button {
  background-color: #fef4f4;
  border: 1px solid #e4315f;
}

.BDHbBq_select.BDHbBq_error button:hover {
  background-color: #fef4f4 !important;
  border: 1px solid #e4315f !important;
}

.BDHbBq_select button {
  width: 100%;
  box-sizing: border-box;
  color: #080828;
  height: 36px;
  text-align: left;
  background-color: #f8f8f9;
  border: 1px solid #f8f8f9;
  border-radius: 4px;
  outline: none;
  padding-left: 12px;
  padding-right: 28px;
  font-size: 14px;
  position: relative;
}

.BDHbBq_select button .BDHbBq_selectAngle {
  transition: transform .2s;
  position: absolute;
  top: 45%;
  right: 12px;
  transform: translateY(-50%);
}

.BDHbBq_select button .BDHbBq_placeholder {
  color: #93939f;
}

.BDHbBq_select button .BDHbBq_selectedTitle {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.BDHbBq_select button .BDHbBq_selectedTitle span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.BDHbBq_select button:hover:not(:disabled) {
  background-color: #f6f6ff;
}

.BDHbBq_select button:focus {
  border: 1px solid #4149f7;
}

.BDHbBq_select button:disabled {
  padding-left: 12px;
  padding-right: 12px;
}

.BDHbBq_select ul.BDHbBq_options {
  max-height: 200px;
  overscroll-behavior: none;
  z-index: 9999;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  margin-top: 0;
  list-style: none;
  display: none;
  position: absolute;
  top: 40px;
  overflow: auto;
  box-shadow: 0 2px 6px #0808281a;
}

.BDHbBq_select ul.BDHbBq_options.BDHbBq_top {
  top: unset;
  bottom: 40px;
}

.BDHbBq_select ul.BDHbBq_options.BDHbBq_show {
  display: block;
}

.BDHbBq_select ul.BDHbBq_options li {
  text-align: start;
  border-bottom: 1px solid #e8e9ed;
  flex-direction: row;
  align-items: center;
  margin: 0 12px;
  padding: 8px 0 9px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.BDHbBq_select ul.BDHbBq_options li span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.BDHbBq_select ul.BDHbBq_options li.BDHbBq_delete {
  color: #e4315f;
}

.BDHbBq_select ul.BDHbBq_options li.BDHbBq_hidden {
  display: none;
}

.BDHbBq_select ul.BDHbBq_options li[disabled] {
  color: #bfc1ca;
}

.BDHbBq_select ul.BDHbBq_options li:last-child {
  border-bottom: none;
}

.BDHbBq_select ul.BDHbBq_options li:active, .BDHbBq_select ul.BDHbBq_options li[aria-selected="true"] {
  color: #4149f7;
}

.BDHbBq_select ul.BDHbBq_options li:active span, .BDHbBq_select ul.BDHbBq_options li[aria-selected="true"] span {
  padding-right: 12px;
}

.BDHbBq_select ul.BDHbBq_options li:active .BDHbBq_iconSelected, .BDHbBq_select ul.BDHbBq_options li[aria-selected="true"] .BDHbBq_iconSelected {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.BDHbBq_select ul.BDHbBq_options li:active, .BDHbBq_select ul.BDHbBq_options li:focus, .BDHbBq_select ul.BDHbBq_options li:hover, .BDHbBq_select ul.BDHbBq_options li[aria-selected="true"] {
  cursor: pointer;
}

.BDHbBq_select.BDHbBq_buttonLike button {
  cursor: pointer;
  background-color: #f3f4f6;
  font-weight: 600;
}

.BDHbBq_select.BDHbBq_buttonLike button:hover {
  background-color: #f3f4f6;
  border: 1px solid #93939f;
}

.BDHbBq_select.BDHbBq_buttonLike button:hover:disabled {
  border: 1px solid #0000;
}

.BDHbBq_select.BDHbBq_buttonLike button[disabled] {
  cursor: default;
}

.BDHbBq_select.BDHbBq_buttonLike button .BDHbBq_placeholder {
  color: #080828;
}

.BDHbBq_select.BDHbBq_buttonLike ul.BDHbBq_options {
  width: 200px;
  right: 0;
}

.BDHbBq_select.BDHbBq_buttonLike.BDHbBq_active button {
  border: 1px solid #93939f;
}

@keyframes _9Q2cSW_zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.5, .5, .5);
  }

  50% {
    opacity: 1;
  }

  70% {
    transform: scale3d(1.01, 1.01, 1.01);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

._9Q2cSW_modal {
  z-index: 9999;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #000000b3;
  padding-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

._9Q2cSW_modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

._9Q2cSW_modalContent {
  width: 70%;
  max-width: 640px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  animation: _9Q2cSW_zoomIn .4s cubic-bezier(.68, -.55, .27, 1.55) both;
}

._9Q2cSW_header {
  border-bottom: 1px solid #e8e9ed;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  display: flex;
}

._9Q2cSW_header ._9Q2cSW_title {
  font-weight: 600;
}

._9Q2cSW_header ._9Q2cSW_steps ._9Q2cSW_activeStep {
  color: #4149f7;
  font-weight: 600;
}

._9Q2cSW_body {
  background-color: #fbfcff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px 20px;
  display: flex;
}

._9Q2cSW_body ._9Q2cSW_mainContent {
  flex-direction: column;
  padding: 8px 0 51px;
  display: flex;
  position: relative;
}

._9Q2cSW_body ._9Q2cSW_mainContent ._9Q2cSW_inviteLabel {
  font-weight: 600;
  position: absolute;
  top: 17px;
  left: 0;
}

._9Q2cSW_body ._9Q2cSW_mainContent ._9Q2cSW_selectRole {
  width: 95px;
  position: absolute;
  top: 8px;
  right: 12px;
}

._9Q2cSW_body ._9Q2cSW_mainContent ._9Q2cSW_inputWrapper {
  max-height: 200px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #bfc1ca;
  flex-flow: wrap;
  gap: 8px;
  margin-bottom: 20px;
  padding-top: 10px;
  display: flex;
  overflow: auto;
}

._9Q2cSW_body ._9Q2cSW_mainContent ._9Q2cSW_inputWrapper._9Q2cSW_inputFocused {
  border-bottom: 1px solid #4149f7;
}

._9Q2cSW_body ._9Q2cSW_mainContent ._9Q2cSW_inputWrapper._9Q2cSW_withControls {
  padding-left: 30px;
  padding-right: 96px;
}

._9Q2cSW_body ._9Q2cSW_mainContent ._9Q2cSW_inputWrapper._9Q2cSW_withControls input {
  width: unset;
  flex-grow: 1;
}

._9Q2cSW_body ._9Q2cSW_mainContent ._9Q2cSW_listTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
}

._9Q2cSW_body ._9Q2cSW_mainContent ._9Q2cSW_listWrapper {
  max-height: 300px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: calc(100% + 40px);
  margin-left: -20px;
  overflow: auto;
}

._9Q2cSW_body ._9Q2cSW_mainContent ._9Q2cSW_listWrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

._9Q2cSW_body ._9Q2cSW_mainContent ._9Q2cSW_listWrapper ._9Q2cSW_listItem {
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: 1px solid #e8e9ed;
  flex-direction: row;
  align-items: center;
  padding: 12px 20px 13px;
  display: flex;
}

._9Q2cSW_body ._9Q2cSW_mainContent ._9Q2cSW_listWrapper ._9Q2cSW_listItem:hover {
  background-color: #f6f6ff;
}

._9Q2cSW_body ._9Q2cSW_mainContent ._9Q2cSW_listWrapper ._9Q2cSW_listChip {
  margin-right: 20px;
}

._9Q2cSW_body ._9Q2cSW_mainContent input {
  width: 100%;
  max-width: 100%;
  background-color: #0000;
  border: 0;
  outline: 0;
  padding-bottom: 19px;
  padding-left: 8px;
}

._9Q2cSW_body ._9Q2cSW_mainContent input::placeholder {
  color: #93939f;
}

._9Q2cSW_body ._9Q2cSW_buttonGroup {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

._9Q2cSW_body ._9Q2cSW_buttonGroupCol {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

@keyframes qKDxWW_zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.5, .5, .5);
  }

  50% {
    opacity: 1;
  }

  70% {
    transform: scale3d(1.01, 1.01, 1.01);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.qKDxWW_modal {
  z-index: 9999;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #000000b3;
  padding-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.qKDxWW_modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.qKDxWW_modalContent {
  width: 70%;
  max-width: 640px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  animation: qKDxWW_zoomIn .4s cubic-bezier(.68, -.55, .27, 1.55) both;
}

.qKDxWW_header {
  border-bottom: 1px solid #e8e9ed;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  display: flex;
}

.qKDxWW_header .qKDxWW_title {
  align-items: center;
  gap: 8px;
  font-weight: 600;
  display: flex;
}

.qKDxWW_body {
  background-color: #fbfcff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px 20px;
  display: flex;
}

.qKDxWW_body .qKDxWW_mainContent {
  padding: 0 0 46px;
  display: flex;
}

.qKDxWW_body .qKDxWW_modelTitle {
  font-weight: 700;
}

.qKDxWW_body .qKDxWW_buttonGroup {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.qKDxWW_body .qKDxWW_checkboxGroupTitle {
  white-space: nowrap;
  position: absolute;
  top: -20px;
}

.qKDxWW_body .qKDxWW_checkboxGroupTitle strong {
  font-weight: 600;
}

.qKDxWW_body .qKDxWW_buttonGroupCol {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

:root {
  --rt-color-white: #fff;
  --rt-color-dark: #222;
  --rt-color-success: #8dc572;
  --rt-color-error: #be6464;
  --rt-color-warning: #f0ad4e;
  --rt-color-info: #337ab7;
  --rt-opacity: .9;
}

.core-styles-module_tooltip__3vRRp {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  will-change: opacity, visibility;
  transition: opacity .3s ease-out;
  position: absolute;
  top: 0;
  left: 0;
}

.core-styles-module_fixed__pcSol {
  position: fixed;
}

.core-styles-module_arrow__cvMwQ {
  background: inherit;
  position: absolute;
}

.core-styles-module_noArrow__xock6 {
  display: none;
}

.core-styles-module_clickable__ZuTTB {
  pointer-events: auto;
}

.core-styles-module_show__Nt9eE {
  visibility: visible;
  opacity: var(--rt-opacity);
}

.styles-module_tooltip__mnnfp {
  width: max-content;
  border-radius: 3px;
  padding: 8px 16px;
  font-size: 90%;
}

.styles-module_arrow__K0L3T {
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
}

.styles-module_dark__xNqje {
  background: var(--rt-color-dark);
  color: var(--rt-color-white);
}

.styles-module_light__Z6W-X {
  background-color: var(--rt-color-white);
  color: var(--rt-color-dark);
}

.styles-module_success__A2AKt {
  background-color: var(--rt-color-success);
  color: var(--rt-color-white);
}

.styles-module_warning__SCK0X {
  background-color: var(--rt-color-warning);
  color: var(--rt-color-white);
}

.styles-module_error__JvumD {
  background-color: var(--rt-color-error);
  color: var(--rt-color-white);
}

.styles-module_info__BWdHW {
  background-color: var(--rt-color-info);
  color: var(--rt-color-white);
}

.MM_u4a_exportItem {
  width: 100%;
  align-items: center;
  gap: 8px;
  margin-left: 5px;
  display: flex;
}

@keyframes Sb0M7a_zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.5, .5, .5);
  }

  50% {
    opacity: 1;
  }

  70% {
    transform: scale3d(1.01, 1.01, 1.01);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.Sb0M7a_modal {
  z-index: 9999;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #000000b3;
  padding-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.Sb0M7a_modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.Sb0M7a_modalContent {
  width: 70%;
  max-width: 640px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  animation: Sb0M7a_zoomIn .4s cubic-bezier(.68, -.55, .27, 1.55) both;
}

.Sb0M7a_header {
  border-bottom: 1px solid #e8e9ed;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  display: flex;
}

.Sb0M7a_header .Sb0M7a_title {
  font-weight: 600;
}

.Sb0M7a_body {
  background-color: #fbfcff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px 20px;
  display: flex;
}

.Sb0M7a_body .Sb0M7a_mainContent {
  padding: 0 0 46px;
  display: flex;
}

.Sb0M7a_body .Sb0M7a_modelTitle {
  font-weight: 700;
}

.Sb0M7a_body .Sb0M7a_buttonGroup {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

@keyframes XUdkaW_zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.5, .5, .5);
  }

  50% {
    opacity: 1;
  }

  70% {
    transform: scale3d(1.01, 1.01, 1.01);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.XUdkaW_modal {
  z-index: 9999;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #000000b3;
  padding-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.XUdkaW_modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.XUdkaW_modalContent {
  width: 70%;
  max-width: 640px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  animation: XUdkaW_zoomIn .4s cubic-bezier(.68, -.55, .27, 1.55) both;
}

.XUdkaW_header {
  border-bottom: 1px solid #e8e9ed;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  display: flex;
}

.XUdkaW_header .XUdkaW_title {
  align-items: center;
  gap: 8px;
  font-weight: 600;
  display: flex;
}

.XUdkaW_body {
  background-color: #fbfcff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px 20px;
  display: flex;
}

.XUdkaW_body .XUdkaW_mainContent {
  padding: 0 0 46px;
  display: flex;
}

.XUdkaW_body .XUdkaW_modelTitle {
  font-weight: 700;
}

.XUdkaW_body .XUdkaW_buttonGroup {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.XUdkaW_body .XUdkaW_checkboxGroupTitle {
  white-space: nowrap;
  position: absolute;
  top: -20px;
}

.XUdkaW_body .XUdkaW_checkboxGroupTitle strong {
  font-weight: 600;
}

.XUdkaW_body .XUdkaW_buttonGroupCol {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

._9nHfyq_iconWrapper {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  display: flex;
  position: relative;
  overflow: visible;
}

._9nHfyq_iconWrapper:hover svg * {
  stroke: #4149f7;
}

._9nHfyq_errorCount {
  color: #fff;
  z-index: 1;
  background-color: #e4315f;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 2px 6px 3px;
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
  display: flex;
  position: absolute;
  top: -4px;
  right: -8px;
}

._9nHfyq_messageWrapper {
  z-index: 999999;
  width: 470px;
  user-select: none;
  cursor: grab;
  background: #fff5f5;
  border: 1px solid #f9c0c1;
  border-radius: 4px;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 8px;
  display: flex;
}

._9nHfyq_message {
  flex-grow: 1;
  justify-content: space-between;
  display: flex;
  position: relative;
}

._9nHfyq_messageLeft {
  flex-direction: row;
  flex-grow: 1;
  gap: 10px;
  display: flex;
}

._9nHfyq_messageLeft > svg {
  min-width: 20px;
  min-height: 20px;
}

._9nHfyq_messageContent {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  padding: 2px 0;
  display: flex;
}

._9nHfyq_messageContent h4, ._9nHfyq_messageContent li, ._9nHfyq_messageContent a, ._9nHfyq_messageContent a:visited {
  color: #080828;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
}

._9nHfyq_messageContent h4 {
  font-weight: 600;
}

._9nHfyq_messageContent a, ._9nHfyq_messageContent a:visited {
  color: #4149f7;
  font-weight: 600;
  text-decoration: none;
}

._9nHfyq_closeIcon {
  cursor: pointer;
}

@keyframes KZn1-a_zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.5, .5, .5);
  }

  50% {
    opacity: 1;
  }

  70% {
    transform: scale3d(1.01, 1.01, 1.01);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.KZn1-a_modal {
  z-index: 9999;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #000000b3;
  padding-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.KZn1-a_modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.KZn1-a_modalContent {
  width: 80%;
  height: calc(100vh - 180px);
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  animation: KZn1-a_zoomIn .4s cubic-bezier(.68, -.55, .27, 1.55) both;
}

.KZn1-a_header {
  border-bottom: 1px solid #e8e9ed;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  display: flex;
}

.KZn1-a_header .KZn1-a_title {
  font-weight: 600;
}

.KZn1-a_header .KZn1-a_steps .KZn1-a_activeStep {
  color: #4149f7;
  font-weight: 600;
}

.KZn1-a_body {
  background-color: #fbfcff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px 20px;
  display: flex;
}

.KZn1-a_body .KZn1-a_descriptionContent {
  flex-direction: column;
  gap: 8px;
  padding-top: 12px;
  padding-bottom: 46px;
  display: flex;
}

.KZn1-a_body .KZn1-a_descriptionContent strong {
  font-weight: 600;
}

.KZn1-a_body .KZn1-a_mainContent {
  padding: 0 0 46px;
  display: flex;
  position: relative;
}

.KZn1-a_body .KZn1-a_mainContent .KZn1-a_inviteLabel {
  font-weight: 600;
  position: absolute;
  top: 17px;
  left: 0;
}

.KZn1-a_body .KZn1-a_mainContent .KZn1-a_selectRole {
  width: 90px;
  position: absolute;
  top: 8px;
  right: 12px;
}

.KZn1-a_body .KZn1-a_mainContent .KZn1-a_inputWrapper {
  width: 100%;
  border-bottom: 1px solid #bfc1ca;
  flex-flow: wrap;
  gap: 8px;
  display: flex;
}

.KZn1-a_body .KZn1-a_mainContent .KZn1-a_inputWrapper.KZn1-a_inputFocused {
  border-bottom: 1px solid #4149f7;
}

.KZn1-a_body .KZn1-a_mainContent .KZn1-a_inputWrapper.KZn1-a_withControls {
  padding-left: 30px;
  padding-right: 96px;
}

.KZn1-a_body .KZn1-a_mainContent .KZn1-a_inputWrapper.KZn1-a_withControls input {
  width: unset;
  flex-grow: 1;
}

.KZn1-a_body .KZn1-a_mainContent input {
  width: 100%;
  max-width: 100%;
  background-color: #0000;
  border: 0;
  outline: 0;
  padding-bottom: 19px;
  padding-left: 8px;
}

.KZn1-a_body .KZn1-a_mainContent input::placeholder {
  color: #93939f;
}

.KZn1-a_body .KZn1-a_buttonGroup {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.KZn1-a_body .KZn1-a_buttonGroupCol {
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
  display: flex;
}

.monaco-placeholder {
  white-space: pre-wrap;
  color: #b1b1ba;
  pointer-events: none;
  user-select: none;
  font-size: 14px;
  display: block;
  position: absolute;
  left: 11px;
}

.monaco-editor .scroll-decoration {
  box-shadow: none !important;
}

.monaco-editor.focused {
  --vscode-editor-background: #fff;
}

.margin {
  display: none !important;
}

.bracket-highlighting-0, .bracket-highlighting-1, .bracket-highlighting-2, .bracket-highlighting-3, .bracket-highlighting-4, .bracket-highlighting-5, .bracket-highlighting-6, .bracket-highlighting-7, .bracket-highlighting-8, .bracket-highlighting-9, .bracket-highlighting-10, .bracket-highlighting-11, .bracket-highlighting-12, .bracket-highlighting-13, .bracket-highlighting-14, .bracket-highlighting-15, .bracket-highlighting-16, .bracket-highlighting-17, .bracket-highlighting-18, .bracket-highlighting-19, .bracket-highlighting-20, .bracket-highlighting-21, .bracket-highlighting-22, .bracket-highlighting-23, .bracket-highlighting-24, .bracket-highlighting-25, .bracket-highlighting-26, .bracket-highlighting-27, .bracket-highlighting-28, .bracket-highlighting-29, .bracket-highlighting-30 {
  color: #000 !important;
}

.codicon-symbol-keyword:before {
  width: 16px;
  height: 16px;
  background-image: url("suggestionIcon.b05e81d1.png");
  background-size: 16px 16px;
  content: " " !important;
}

.ExpIeq_loader {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ExpIeq_scrollConfig, .ExpIeq_codeEditor {
  scrollbar-width: thin;
  scrollbar-color: #e8e9ed #f8f8f9;
}

.ExpIeq_scrollConfig ::-webkit-scrollbar, .ExpIeq_codeEditor ::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

.ExpIeq_scrollConfig ::-webkit-scrollbar-track, .ExpIeq_codeEditor ::-webkit-scrollbar-track {
  width: 6px;
  background-color: #f8f8f9;
}

.ExpIeq_scrollConfig ::-webkit-scrollbar-corner, .ExpIeq_codeEditor ::-webkit-scrollbar-corner {
  background-color: #f8f8f9;
}

.ExpIeq_scrollConfig ::-webkit-scrollbar-thumb, .ExpIeq_codeEditor ::-webkit-scrollbar-thumb {
  background-color: #e8e9ed;
  border: 4px solid #f8f8f9;
  border-radius: 24px;
}

.ExpIeq_scrollConfigInline ::-webkit-scrollbar {
  width: 14px;
  height: 6px;
}

.ExpIeq_codeEditor {
  background-color: #f8f8f9;
  border: 1px solid #f8f8f9;
  border-radius: 4px;
  padding: 8px 0 4px 6px;
  position: relative;
}

.ExpIeq_codeEditor.ExpIeq_error {
  background-color: #fef4f4;
  border: 1px solid #e4315f;
  border-radius: 4px;
}

.ExpIeq_codeEditor * {
  font-family: Menlo, monospace;
  font-size: 14px;
  line-height: 23px;
}

.ExpIeq_codeEditor .ExpIeq_expandWrapper {
  z-index: 100;
  position: absolute;
  bottom: 12px;
  right: 12px;
}

.ExpIeq_focusedEditor {
  background-color: #fff;
  border: 1px solid #4149f7;
}

.ExpIeq_focusedEditor .ace_content {
  background-color: #fff !important;
}

.JK42Ta_wrapCollabsible {
  margin-bottom: 1.2rem 0;
}

input[type="checkbox"] {
  display: none;
}

.JK42Ta_lblToggle {
  width: 100%;
  letter-spacing: 0;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  transition: all .25s ease-out;
  display: flex;
}

.JK42Ta_lblToggle.JK42Ta_lblWithIcon {
  font-weight: 400;
}

.JK42Ta_lblToggle.JK42Ta_lblWithIcon > span {
  cursor: pointer;
}

.JK42Ta_lblToggle .JK42Ta_hoverIcon {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.JK42Ta_lblToggle .JK42Ta_hoverIcon:hover svg * {
  stroke: #4149f7;
}

.JK42Ta_lblToggle:not(.JK42Ta_lblWithIcon) {
  cursor: pointer;
}

.JK42Ta_lblToggle .JK42Ta_arrow {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.JK42Ta_lblToggle .JK42Ta_arrow svg {
  transition: all .25s ease-in-out;
  transform: rotate(-90deg);
}

.JK42Ta_toggle:checked + .JK42Ta_lblToggle.JK42Ta_lblWithIcon {
  font-weight: 600;
}

.JK42Ta_toggle:checked + .JK42Ta_lblToggle svg {
  transform: rotate(0);
  transition: all .25s ease-in-out;
}

.JK42Ta_collapsibleContent {
  max-height: 0;
  transition: max-height .25s ease-in-out;
  overflow: hidden;
}

.JK42Ta_toggle:checked + .JK42Ta_lblToggle + .JK42Ta_collapsibleContent {
  max-height: 100vh;
  overflow: visible;
}

.JK42Ta_toggle:checked + .JK42Ta_lblToggle {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.JK42Ta_collapsibleContent .JK42Ta_contentInner {
  width: 100%;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  display: flex;
  position: relative;
}

.JK42Ta_withIconContent {
  border-bottom: 1px solid #e8e9ed;
  padding-bottom: 20px;
}

@keyframes bojh2G_zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.5, .5, .5);
  }

  50% {
    opacity: 1;
  }

  70% {
    transform: scale3d(1.01, 1.01, 1.01);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.bojh2G_modal {
  z-index: 9999;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #000000b3;
  padding-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.bojh2G_modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.bojh2G_modalContent {
  width: 70%;
  max-width: 640px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  animation: bojh2G_zoomIn .4s cubic-bezier(.68, -.55, .27, 1.55) both;
}

.bojh2G_header {
  border-bottom: 1px solid #e8e9ed;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  display: flex;
}

.bojh2G_header .bojh2G_title {
  font-weight: 600;
}

.bojh2G_body {
  background-color: #fbfcff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px 20px;
  display: flex;
}

.bojh2G_body .bojh2G_mainContent {
  padding: 0 0 46px;
  display: flex;
}

.bojh2G_body .bojh2G_modelTitle {
  font-weight: 700;
}

.bojh2G_body .bojh2G_buttonGroup {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

@keyframes R7FiOq_zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.5, .5, .5);
  }

  50% {
    opacity: 1;
  }

  70% {
    transform: scale3d(1.01, 1.01, 1.01);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.R7FiOq_modal {
  z-index: 9999;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #000000b3;
  padding-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.R7FiOq_modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.R7FiOq_modalContent {
  width: 70%;
  max-width: 640px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  animation: R7FiOq_zoomIn .4s cubic-bezier(.68, -.55, .27, 1.55) both;
}

.R7FiOq_header {
  border-bottom: 1px solid #e8e9ed;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  display: flex;
}

.R7FiOq_header .R7FiOq_title {
  align-items: center;
  gap: 8px;
  font-weight: 600;
  display: flex;
}

.R7FiOq_body {
  background-color: #fbfcff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px 20px;
  display: flex;
}

.R7FiOq_body .R7FiOq_mainContent {
  padding: 0 0 46px;
  display: flex;
}

.R7FiOq_body .R7FiOq_modelTitle {
  font-weight: 700;
}

.R7FiOq_body .R7FiOq_buttonGroup {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.R7FiOq_body .R7FiOq_checkboxGroupTitle {
  white-space: nowrap;
  position: absolute;
  top: -20px;
}

.R7FiOq_body .R7FiOq_checkboxGroupTitle strong {
  font-weight: 600;
}

.R7FiOq_body .R7FiOq_buttonGroupCol {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

@keyframes szbuKW_zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.5, .5, .5);
  }

  50% {
    opacity: 1;
  }

  70% {
    transform: scale3d(1.01, 1.01, 1.01);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.szbuKW_modal {
  z-index: 9999;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #000000b3;
  padding-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.szbuKW_modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.szbuKW_modalContent {
  width: 70%;
  max-width: 640px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  animation: szbuKW_zoomIn .4s cubic-bezier(.68, -.55, .27, 1.55) both;
}

.szbuKW_header {
  border-bottom: 1px solid #e8e9ed;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  display: flex;
}

.szbuKW_header .szbuKW_title {
  font-weight: 600;
}

.szbuKW_body {
  background-color: #fbfcff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px 20px;
  display: flex;
}

.szbuKW_body .szbuKW_mainContent {
  padding: 0 0 46px;
  display: flex;
}

.szbuKW_body .szbuKW_modelTitle {
  color: #4149f7;
}

.szbuKW_body .szbuKW_buttonGroup {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

@keyframes xvZMDW_zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.5, .5, .5);
  }

  50% {
    opacity: 1;
  }

  70% {
    transform: scale3d(1.01, 1.01, 1.01);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.xvZMDW_modal {
  z-index: 9999;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #000000b3;
  padding-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.xvZMDW_modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.xvZMDW_modalContent {
  width: 70%;
  max-width: 640px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  animation: xvZMDW_zoomIn .4s cubic-bezier(.68, -.55, .27, 1.55) both;
}

.xvZMDW_header {
  border-bottom: 1px solid #e8e9ed;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  display: flex;
}

.xvZMDW_header .xvZMDW_title {
  font-weight: 600;
}

.xvZMDW_body {
  background-color: #fbfcff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px 20px;
  display: flex;
}

.xvZMDW_body .xvZMDW_mainContent {
  padding: 0 0 46px;
  display: flex;
}

.xvZMDW_body .xvZMDW_modelTitle {
  font-weight: 700;
}

.xvZMDW_body .xvZMDW_buttonGroup {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

@keyframes NgVBRW_zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.5, .5, .5);
  }

  50% {
    opacity: 1;
  }

  70% {
    transform: scale3d(1.01, 1.01, 1.01);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.NgVBRW_modal {
  z-index: 9999;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #000000b3;
  padding-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.NgVBRW_modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.NgVBRW_modalContent {
  width: 70%;
  max-width: 640px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  animation: NgVBRW_zoomIn .4s cubic-bezier(.68, -.55, .27, 1.55) both;
}

.NgVBRW_header {
  border-bottom: 1px solid #e8e9ed;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  display: flex;
}

.NgVBRW_header .NgVBRW_title {
  font-weight: 600;
}

.NgVBRW_body {
  background-color: #fbfcff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px 20px;
  display: flex;
}

.NgVBRW_body .NgVBRW_mainContent {
  padding: 0 0 46px;
  display: flex;
}

.NgVBRW_body .NgVBRW_modelTitle {
  font-weight: 700;
}

.NgVBRW_body .NgVBRW_buttonGroup {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  position: relative;
}

.NgVBRW_body .NgVBRW_buttonGroupCol {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

.NgVBRW_body .NgVBRW_checkboxGroupTitle {
  white-space: nowrap;
  position: absolute;
  top: -20px;
}

.NgVBRW_body .NgVBRW_checkboxGroupTitle strong {
  font-weight: 600;
}

.rDht3W_collapsible {
  width: 100%;
}

.rDht3W_collapsible:last-child {
  margin-bottom: 19px;
}

.rDht3W_collapsibleHeader {
  cursor: pointer;
  align-items: center;
  gap: 12px;
  padding: 10px;
  font-weight: 600;
  display: flex;
}

.rDht3W_collapsibleHeader .rDht3W_iconGroup {
  flex-direction: row;
  align-items: center;
  gap: 6px;
  display: flex;
}

.rDht3W_collapsibleHeader span {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  overflow: hidden;
}

.rDht3W_arrow:before {
  content: "▼";
  font-size: 24px;
  transition: transform .3s ease-in-out;
  transform: rotate(0deg);
}

.rDht3W_arrow.rDht3W_up:before {
  transform: rotate(-180deg);
}

.rDht3W_collapsed .rDht3W_collapsibleContent {
  max-height: 0;
  transition: max-height .3s ease-in-out;
  overflow: hidden;
}

.rDht3W_collapsibleContent {
  transition: max-height .3s ease-in-out;
  overflow: hidden;
}

.rDht3W_collapsibleContent.rDht3W_collapsed {
  max-height: 0;
}

.rDht3W_collapsibleTable {
  flex-direction: column;
  display: flex;
}

.rDht3W_collapsibleTable li {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 5px 16px 5px 36px;
  display: flex;
  position: relative;
}

.rDht3W_collapsibleTable li.rDht3W_foreignKeyItem {
  color: #93939f;
  cursor: pointer;
}

.rDht3W_collapsibleTable li.rDht3W_foreignKeyItem:hover {
  color: #4149f7;
}

.rDht3W_collapsibleTable li.rDht3W_foreignKeyItem:hover .rDht3W_foreignKeyItemGroup svg * {
  stroke: #4149f7;
}

.rDht3W_collapsibleTable li.rDht3W_foreignKeyItem:hover .rDht3W_typeGroup {
  color: #4149f7;
}

.rDht3W_collapsibleTable li.rDht3W_foreignKeyItem:hover .rDht3W_typeGroup svg * {
  fill: #4149f7;
}

.rDht3W_collapsibleTable li .rDht3W_foreignKeyItemGroup {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  display: flex;
  overflow: hidden;
}

.rDht3W_collapsibleTable li .rDht3W_primaryKey {
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  left: 12px;
}

.rDht3W_collapsibleTable li span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.rDht3W_collapsibleTable li:hover {
  background-color: #f1f4ff;
}

.rDht3W_collapsibleTable .rDht3W_typeGroup {
  text-transform: lowercase;
  color: #93939f;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  display: flex;
}

.rDht3W_collapsibleTable .rDht3W_typeGroup.rDht3W_activeLink {
  color: #4149f7;
}

.rDht3W_collapsibleTable .rDht3W_typeGroup.rDht3W_activeLink svg * {
  fill: #4149f7;
}

.rDht3W_arrowAngle {
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  transition: all .3s;
  display: flex;
  transform: rotate(0deg);
}

.rDht3W_arrowAngle.rDht3W_openAngle {
  transition: all .3s;
  transform: rotate(-90deg);
}

.rDht3W_highlighted {
  color: #3fb245;
  background-color: #e6fce5;
}

.rDht3W_highlighted .rDht3W_typeGroup {
  color: #3fb245;
}

.rDht3W_highlighted .rDht3W_typeGroup svg * {
  fill: #3fb245;
}

.JOH7rG_layout {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  min-height: 100%;
  background-color: #f8f8f9;
  border-radius: 4px;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: flex-start;
  margin-bottom: 19px;
  padding: 8px 0;
  display: flex;
  position: absolute;
  overflow: auto;
}

.JOH7rG_loaderWrapper, .JOH7rG_errorWrapper {
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 19px;
  padding: 0 8px;
  display: flex;
}

.JOH7rG_errorIcon {
  height: 16px;
  margin-bottom: 4px;
}

.zqYRkW_commitDiff {
  background-color: #fff;
  border-radius: 4px;
  margin: 12px 20px;
  padding: 8px 12px;
  position: relative;
}

.zqYRkW_commitDiff .zqYRkW_table {
  border-collapse: separate;
  border-spacing: 12px;
  width: 100%;
  margin: 0;
}

.zqYRkW_commitDiff .zqYRkW_table .zqYRkW_extendedText td {
  overflow: initial;
  text-overflow: unset;
  overflow-wrap: break-word;
  white-space: unset;
}

.zqYRkW_commitDiff .zqYRkW_table td, .zqYRkW_commitDiff .zqYRkW_table th {
  max-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.zqYRkW_commitDiff .zqYRkW_table th {
  color: #080828;
  font-family: Open Sans, sans-serif;
  font-size: 11px;
  font-weight: 700;
}

.zqYRkW_commitDiff .zqYRkW_table tbody tr td {
  font-family: Open Sans, sans-serif;
  font-size: 11px;
  font-weight: 400;
}

.zqYRkW_commitDiff .zqYRkW_table tbody tr td:nth-child(1) {
  color: #080828;
}

.zqYRkW_commitDiff .zqYRkW_table tbody tr td:nth-child(2) {
  color: #93939f;
}

.zqYRkW_commitDiff .zqYRkW_table tbody tr td:nth-child(3) {
  color: #3fb245;
}

.lTkMZq_debugConsole {
  box-sizing: border-box;
  width: 100%;
  background-color: #f6f6ff;
  border-radius: 4px;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  padding: 8px 8px 9px;
  display: flex;
  position: relative;
}

.lTkMZq_debugConsole .lTkMZq_vLine {
  border-left: 4px solid #4149f7;
  border-radius: 2px;
}

.lTkMZq_debugConsole .lTkMZq_method {
  text-transform: uppercase;
  font-weight: 600;
}

.lTkMZq_debugConsole .lTkMZq_url {
  width: calc(100% - 76px);
  text-overflow: ellipsis;
  overflow: hidden;
}

.lTkMZq_debugConsole .lTkMZq_url > span {
  color: #4149f7;
  font-family: Menlo, sans-serif;
}

.lTkMZq_debugConsole .lTkMZq_action {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

._4ELaua_header {
  border-bottom: 1px solid #e8e9ed;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  display: flex;
}

._4ELaua_header ._4ELaua_title {
  font-weight: 600;
}

._4ELaua_body {
  background-color: #fbfcff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px 20px;
  display: flex;
}

._4ELaua_body ._4ELaua_mainContent {
  flex-direction: column;
  padding: 8px 0 51px;
  display: flex;
  position: relative;
}

._4ELaua_body ._4ELaua_buttonsGroup {
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;
  display: flex;
}

@keyframes i0np6q_zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.5, .5, .5);
  }

  50% {
    opacity: 1;
  }

  70% {
    transform: scale3d(1.01, 1.01, 1.01);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.i0np6q_modal {
  z-index: 1;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #000000b3;
  padding-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.i0np6q_modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.i0np6q_modal .i0np6q_modalContent {
  width: 70%;
  max-width: 640px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  animation: i0np6q_zoomIn .4s cubic-bezier(.68, -.55, .27, 1.55) both;
}

@keyframes uVpmhW_zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.5, .5, .5);
  }

  50% {
    opacity: 1;
  }

  70% {
    transform: scale3d(1.01, 1.01, 1.01);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.uVpmhW_modal {
  z-index: 9999;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #000000b3;
  padding-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.uVpmhW_modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.uVpmhW_modalContent {
  width: 70%;
  max-width: 640px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  animation: uVpmhW_zoomIn .4s cubic-bezier(.68, -.55, .27, 1.55) both;
}

.uVpmhW_header {
  border-bottom: 1px solid #e8e9ed;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  display: flex;
}

.uVpmhW_header .uVpmhW_title {
  font-weight: 600;
}

.uVpmhW_body {
  background-color: #fbfcff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px 20px;
  display: flex;
}

.uVpmhW_body .uVpmhW_mainContent {
  padding: 0 0 46px;
  display: flex;
}

.uVpmhW_body .uVpmhW_modelTitle {
  color: #4149f7;
}

.uVpmhW_body .uVpmhW_buttonGroup {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

@keyframes PCDnaG_zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.5, .5, .5);
  }

  50% {
    opacity: 1;
  }

  70% {
    transform: scale3d(1.01, 1.01, 1.01);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.PCDnaG_modal {
  z-index: 9999;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #000000b3;
  padding-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.PCDnaG_modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.PCDnaG_modalContent {
  width: 70%;
  max-width: 640px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  animation: PCDnaG_zoomIn .4s cubic-bezier(.68, -.55, .27, 1.55) both;
}

.PCDnaG_header {
  border-bottom: 1px solid #e8e9ed;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  display: flex;
}

.PCDnaG_header .PCDnaG_title {
  font-weight: 600;
}

.PCDnaG_header .PCDnaG_steps .PCDnaG_activeStep {
  color: #4149f7;
  font-weight: 600;
}

.PCDnaG_body {
  background-color: #fbfcff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px 20px;
  display: flex;
}

.PCDnaG_body .PCDnaG_mainContent {
  flex-direction: column;
  padding: 8px 0 51px;
  display: flex;
  position: relative;
}

.PCDnaG_body .PCDnaG_mainContent .PCDnaG_inviteLabel {
  font-weight: 600;
  position: absolute;
  top: 17px;
  left: 0;
}

.PCDnaG_body .PCDnaG_mainContent .PCDnaG_selectRole {
  width: 90px;
  position: absolute;
  top: 8px;
  right: 12px;
}

.PCDnaG_body .PCDnaG_mainContent .PCDnaG_inputWrapper {
  max-height: 200px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #bfc1ca;
  flex-flow: wrap;
  gap: 8px;
  margin-bottom: 20px;
  padding-top: 10px;
  display: flex;
  overflow: auto;
}

.PCDnaG_body .PCDnaG_mainContent .PCDnaG_inputWrapper.PCDnaG_inputFocused {
  border-bottom: 1px solid #4149f7;
}

.PCDnaG_body .PCDnaG_mainContent .PCDnaG_inputWrapper.PCDnaG_withControls {
  padding-left: 30px;
  padding-right: 96px;
}

.PCDnaG_body .PCDnaG_mainContent .PCDnaG_inputWrapper.PCDnaG_withControls input {
  width: unset;
  flex-grow: 1;
}

.PCDnaG_body .PCDnaG_mainContent .PCDnaG_listTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
}

.PCDnaG_body .PCDnaG_mainContent .PCDnaG_listWrapper {
  max-height: 300px;
  width: calc(100% + 40px);
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-left: -20px;
  overflow: auto;
}

.PCDnaG_body .PCDnaG_mainContent .PCDnaG_listWrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.PCDnaG_body .PCDnaG_mainContent .PCDnaG_listWrapper .PCDnaG_listItem {
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: 1px solid #e8e9ed;
  flex-direction: row;
  align-items: center;
  padding: 12px 20px 13px;
  display: flex;
}

.PCDnaG_body .PCDnaG_mainContent .PCDnaG_listWrapper .PCDnaG_listItem:hover {
  background-color: #f6f6ff;
}

.PCDnaG_body .PCDnaG_mainContent .PCDnaG_listWrapper .PCDnaG_listChip {
  width: 140px;
  align-items: center;
  margin-right: 20px;
  display: flex;
}

.PCDnaG_body .PCDnaG_mainContent input {
  width: 100%;
  max-width: 100%;
  background-color: #0000;
  border: 0;
  outline: 0;
  padding-bottom: 19px;
  padding-left: 8px;
}

.PCDnaG_body .PCDnaG_mainContent input::placeholder {
  color: #93939f;
}

.PCDnaG_body .PCDnaG_buttonGroup {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.PCDnaG_body .PCDnaG_buttonGroupCol {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

@keyframes _7T7AAq_zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.5, .5, .5);
  }

  50% {
    opacity: 1;
  }

  70% {
    transform: scale3d(1.01, 1.01, 1.01);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

._7T7AAq_modal {
  z-index: 9999;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #000000b3;
  padding-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

._7T7AAq_modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

._7T7AAq_modalContent {
  width: 70%;
  max-width: 640px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  animation: _7T7AAq_zoomIn .4s cubic-bezier(.68, -.55, .27, 1.55) both;
}

._7T7AAq_header {
  border-bottom: 1px solid #e8e9ed;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  display: flex;
}

._7T7AAq_header ._7T7AAq_title {
  font-weight: 600;
}

._7T7AAq_header ._7T7AAq_steps ._7T7AAq_activeStep {
  color: #4149f7;
  font-weight: 600;
}

._7T7AAq_body {
  background-color: #fbfcff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px 20px;
  display: flex;
}

._7T7AAq_body ._7T7AAq_mainContent {
  flex-direction: column;
  padding: 8px 0 51px;
  display: flex;
  position: relative;
}

._7T7AAq_body ._7T7AAq_mainContent ._7T7AAq_inviteLabel {
  font-weight: 600;
  position: absolute;
  top: 17px;
  left: 0;
}

._7T7AAq_body ._7T7AAq_mainContent ._7T7AAq_selectRole {
  min-width: 90px;
  max-width: 200px;
  position: absolute;
  top: 8px;
  right: 12px;
}

._7T7AAq_body ._7T7AAq_mainContent ._7T7AAq_inputWrapper {
  max-height: 200px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #bfc1ca;
  flex-flow: wrap;
  gap: 8px;
  margin-bottom: 20px;
  padding-top: 10px;
  display: flex;
  overflow: auto;
}

._7T7AAq_body ._7T7AAq_mainContent ._7T7AAq_inputWrapper._7T7AAq_inputFocused {
  border-bottom: 1px solid #4149f7;
}

._7T7AAq_body ._7T7AAq_mainContent ._7T7AAq_inputWrapper._7T7AAq_withControls {
  padding-left: 30px;
  padding-right: 96px;
}

._7T7AAq_body ._7T7AAq_mainContent ._7T7AAq_inputWrapper._7T7AAq_withControls input {
  width: unset;
  flex-grow: 1;
}

._7T7AAq_body ._7T7AAq_mainContent ._7T7AAq_listTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
}

._7T7AAq_body ._7T7AAq_mainContent ._7T7AAq_listWrapper {
  max-height: 300px;
  width: calc(100% + 40px);
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-left: -20px;
  overflow: auto;
}

._7T7AAq_body ._7T7AAq_mainContent ._7T7AAq_listWrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

._7T7AAq_body ._7T7AAq_mainContent ._7T7AAq_listWrapper ._7T7AAq_listItem {
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: 1px solid #e8e9ed;
  flex-direction: row;
  align-items: center;
  padding: 12px 20px 13px;
  display: flex;
}

._7T7AAq_body ._7T7AAq_mainContent ._7T7AAq_listWrapper ._7T7AAq_listItem:hover {
  background-color: #f6f6ff;
}

._7T7AAq_body ._7T7AAq_mainContent ._7T7AAq_listWrapper ._7T7AAq_listChip {
  width: 72px;
  align-items: center;
  margin-right: 20px;
  display: flex;
}

._7T7AAq_body ._7T7AAq_mainContent input {
  width: 100%;
  max-width: 100%;
  background-color: #0000;
  border: 0;
  outline: 0;
  padding-bottom: 19px;
  padding-left: 8px;
}

._7T7AAq_body ._7T7AAq_mainContent input::placeholder {
  color: #93939f;
}

._7T7AAq_body ._7T7AAq_buttonGroup {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

._7T7AAq_body ._7T7AAq_buttonGroupCol {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

@keyframes grPXLq_zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.5, .5, .5);
  }

  50% {
    opacity: 1;
  }

  70% {
    transform: scale3d(1.01, 1.01, 1.01);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.grPXLq_modal {
  z-index: 9999;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #000000b3;
  padding-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.grPXLq_modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.grPXLq_modalContent {
  width: 70%;
  max-width: 640px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  animation: grPXLq_zoomIn .4s cubic-bezier(.68, -.55, .27, 1.55) both;
}

.grPXLq_header {
  border-bottom: 1px solid #e8e9ed;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  display: flex;
}

.grPXLq_header .grPXLq_title {
  font-weight: 600;
}

.grPXLq_body {
  background-color: #fbfcff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px 20px;
  display: flex;
}

.grPXLq_body .grPXLq_mainContent {
  flex-direction: column;
  padding: 0 0 46px;
  display: flex;
}

.grPXLq_body .grPXLq_modalSelect {
  margin-top: 20px;
}

.grPXLq_body .grPXLq_selectRole {
  width: 200px;
  margin-top: 10px;
}

.grPXLq_body .grPXLq_modelTitle {
  color: #4149f7;
}

.grPXLq_body .grPXLq_buttonGroup {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

@keyframes fOg-rq_zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.5, .5, .5);
  }

  50% {
    opacity: 1;
  }

  70% {
    transform: scale3d(1.01, 1.01, 1.01);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

.fOg-rq_modal {
  z-index: 9999;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #000000b3;
  padding-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.fOg-rq_modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.fOg-rq_modalContent {
  width: 70%;
  max-width: 640px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  animation: fOg-rq_zoomIn .4s cubic-bezier(.68, -.55, .27, 1.55) both;
}

.fOg-rq_header {
  border-bottom: 1px solid #e8e9ed;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  display: flex;
}

.fOg-rq_header .fOg-rq_title {
  font-weight: 600;
}

.fOg-rq_body {
  background-color: #fbfcff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px 20px;
  display: flex;
}

.fOg-rq_body .fOg-rq_mainContent {
  padding: 0 0 46px;
  display: flex;
}

.fOg-rq_body .fOg-rq_modelTitle {
  font-weight: 700;
}

.fOg-rq_body .fOg-rq_buttonGroup {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

@keyframes _0Uq4_a_zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.5, .5, .5);
  }

  50% {
    opacity: 1;
  }

  70% {
    transform: scale3d(1.01, 1.01, 1.01);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

._0Uq4_a_modal {
  z-index: 9999;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #000000b3;
  padding-top: 60px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

._0Uq4_a_modal::-webkit-scrollbar {
  width: 0;
  height: 0;
}

._0Uq4_a_modalContent {
  width: 70%;
  max-width: 640px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  animation: _0Uq4_a_zoomIn .4s cubic-bezier(.68, -.55, .27, 1.55) both;
}

._0Uq4_a_header {
  border-bottom: 1px solid #e8e9ed;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  display: flex;
}

._0Uq4_a_header ._0Uq4_a_title {
  align-items: center;
  gap: 8px;
  font-weight: 600;
  display: flex;
}

._0Uq4_a_body {
  background-color: #fbfcff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px 20px;
  display: flex;
}

._0Uq4_a_body ._0Uq4_a_mainContent {
  padding: 0 0 46px;
  display: flex;
}

._0Uq4_a_body ._0Uq4_a_modelTitle {
  font-weight: 700;
}

._0Uq4_a_body ._0Uq4_a_buttonGroup {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  position: relative;
}

._0Uq4_a_body ._0Uq4_a_checkboxGroupTitle {
  white-space: nowrap;
  position: absolute;
  top: -20px;
}

._0Uq4_a_body ._0Uq4_a_checkboxGroupTitle strong {
  font-weight: 600;
}

._0Uq4_a_body ._0Uq4_a_buttonGroupCol {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

.c_g-jq_wrapper {
  width: 100%;
  height: 100vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  overflow: scroll;
}

.c_g-jq_wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.c_g-jq_box {
  max-width: 290px;
  flex-direction: column;
  justify-content: start;
  gap: 12px;
  margin-top: 120px;
  margin-bottom: 24px;
  display: flex;
}

.c_g-jq_box .c_g-jq_logoBox {
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
}

.c_g-jq_box .c_g-jq_logoBox h2 {
  color: #000;
  font-family: Avenir, sans-serif;
  font-size: 24px;
  font-weight: 800;
}

.c_g-jq_box .c_g-jq_titleBox {
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  display: flex;
  position: relative;
}

.c_g-jq_box .c_g-jq_titleBox .c_g-jq_actionButton {
  position: relative;
  top: 2px;
}

.c_g-jq_box .c_g-jq_titleBox h3 {
  font-family: Open Sans, sans-serif;
  font-size: 28px;
  font-weight: 400;
}

.c_g-jq_box .c_g-jq_successBox {
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex;
}

.c_g-jq_box .c_g-jq_footerBox {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.c_g-jq_box h3 {
  font-size: 32px;
  font-weight: 700;
}

.c_g-jq_box form {
  width: 280px;
  flex-direction: column;
  display: flex;
}

.c_g-jq_box .c_g-jq_row {
  max-width: 100%;
  width: 100%;
  padding: 12px 0;
  position: relative;
}

.c_g-jq_box .c_g-jq_row label {
  font-size: 14px;
  position: relative;
  top: -4px;
  left: 0;
}

.c_g-jq_box .c_g-jq_row label .c_g-jq_required {
  color: #4149f7;
  position: absolute;
  top: 2px;
  right: -12px;
}

.c_g-jq_box .c_g-jq_row input, .c_g-jq_box .c_g-jq_row button {
  width: 100%;
}

.c_g-jq_box .c_g-jq_row input {
  box-sizing: border-box;
  color: #080828;
  height: 36px;
  background-color: #f8f8f9;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  outline: none;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
}

.c_g-jq_box .c_g-jq_row input::placeholder {
  color: #93939f;
}

.c_g-jq_box .c_g-jq_row input:focus {
  background-color: #fff;
  border: 1px solid #4149f7;
}

.c_g-jq_box .c_g-jq_row input.c_g-jq_errorInput {
  background-color: #fef4f4;
  border: 1px solid #e4315f;
}

.c_g-jq_box .c_g-jq_row .c_g-jq_errorMessage {
  white-space: nowrap;
  color: #e4315f;
  font-size: 10px;
  position: absolute;
  bottom: -4px;
  left: 8px;
  overflow: visible;
}

.c_g-jq_box .c_g-jq_row button {
  cursor: pointer;
  height: 36px;
  box-sizing: border-box;
  color: #fff;
  background-color: #4149f7;
  border: none;
  border-radius: 4px;
  font-weight: 600;
}

.c_g-jq_box .c_g-jq_row button[disabled] {
  color: #bfc1ca;
  cursor: initial;
  background-color: #f3f4f6;
}

.c_g-jq_box .c_g-jq_row button.c_g-jq_buttonSubmitting {
  opacity: .6;
}

.c_g-jq_box .c_g-jq_message {
  margin-top: 12px;
  font-size: 18px;
}

.c_g-jq_box .c_g-jq_message a {
  color: #4149f7;
  font-weight: 600;
  text-decoration: underline;
}

.c_g-jq_loaderWrapper {
  height: 100vh;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
}

.CdvBiq_main {
  box-sizing: border-box;
  flex-direction: row;
  display: flex;
}

.CdvBiq_main .CdvBiq_container {
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: auto;
  padding: 10px;
  display: flex;
}

.CdvBiq_main .CdvBiq_container .CdvBiq_reason {
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
}

.CdvBiq_main .CdvBiq_container .CdvBiq_advice {
  text-align: center;
  color: #080828;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.CdvBiq_main .CdvBiq_container .CdvBiq_link {
  text-align: center;
  color: #4149f7;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  text-decoration: none;
}

.CdvBiq_main .CdvBiq_container .CdvBiq_link:hover {
  cursor: pointer;
}

/*# sourceMappingURL=index.e82f05b5.css.map */
